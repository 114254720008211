<template>
  <div class="content_body TreatBill" v-loading="loading">
    <div class="nav_header">
      <el-form :inline="true" size="small" :model="searchParams" @keyup.enter.native="handleSearch">
        <el-form-item label="订单">
          <el-input v-model="searchParams.BillID" clearable size="small" placeholder="请输入订单编号搜索" @clear="handleSearch"></el-input>
        </el-form-item>

        <el-form-item label="客户">
          <el-input v-model="searchParams.Name" clearable size="small" @clear="handleSearch" placeholder="名字、手机号或客户编号"></el-input>
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker unlink-panels v-model="searchParams.createTime" type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" @clear="handleSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchParams.BillType" placeholder="请选择订单类型" clearable @clear="handleSearch" @change="handleSearch">
            <el-option label="消耗单" value="10"></el-option>
            <el-option label="消耗退单" value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchParams.BillStatus" placeholder="请选择订单状态" clearable @clear="handleSearch" @change="handleSearch">
            <el-option label="已完成" value="20"></el-option>
            <el-option label="已取消" value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="treatList">
        <el-table-column prop="ID" label="订单编号"></el-table-column>
        <el-table-column prop="EntityName" label="门店名称"></el-table-column>
        <el-table-column label="客户信息">
          <template slot-scope="scope">
            <div>{{ scope.row.Name }}<span v-show="scope.row.Code">({{scope.row.Code}})</span></div>
            <div v-if="scope.row.CustomerID != null">手机号：{{ scope.row.PhoneNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="Amount" label="消耗金额">
          <template slot-scope="scope">￥{{ scope.row.Amount | NumFormat }}</template>
        </el-table-column>
        <el-table-column label="订单类型">
          <template slot-scope="scope">
            <div>{{ scope.row.BillType == 10 ? "消耗单" : "消耗退单" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态">
          <template slot-scope="scope">
            <div>{{ scope.row.BillStatus == 20 ? "已完成" : "已取消" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
        <el-table-column prop="BillDate" label="下单时间"></el-table-column>
        <el-table-column prop="CreatedOn" label="录单时间"></el-table-column>
        <el-table-column label="操作" width="90" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--订单详情 弹窗-->
    <el-dialog width="1100px" :visible.sync="dialogVisible">
      <el-tabs v-model="ActiveName">
        <el-tab-pane label="消耗单详情" name="0">
          <div style="height: 60vh" class="font_13">
            <el-scrollbar class="el-scrollbar_height">
              <TreatBillDetailContent :treatInfo="treatInfo"></TreatBillDetailContent>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户签字" name="1">
           <div style="height:60vh;" >
            <el-scrollbar class="el-scrollbar_height">
              <div v-if="Signature.CreatedOn" class="marbm_10">签字时间：{{Signature.CreatedOn}}</div>
              <el-card class="box-card" v-if="Signature.ImageURL">
                  <el-image style="width: 320px; height: 450px;"  :src="Signature.ImageURL" fit="cover"></el-image>
              </el-card>
            </el-scrollbar>
           </div>
        </el-tab-pane>
        <el-tab-pane label="图片上传" name="2">
          <div style="height:60vh;">
            <el-scrollbar class="el-scrollbar_height">
              <el-upload action="#" list-type="picture-card" :before-upload="updataimg" :file-list="fileList" :http-request="unset" :multiple="true" accept=".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <el-image class="el-upload-list__item-thumbnail" :id="file.name" :src="file.url" :preview-src-list="showFileList" :z-index="99999999999" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span v-if="isDelete" class="el-upload-list__item-delete" @click="deleteImg(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <!-- <el-dialog :visible.sync="dialogVisibleimg" :append-to-body="true">
                <img width="100%" :src="dialogImageUrl" alt />
              </el-dialog> -->
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer text_center" :hidden="ActiveName=='1'">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="treatCashierReceiptDialogVisible = true" size="small">打印小票</el-button>
        <el-button type="danger" @click="cancelOrder" size="small" v-prevent-click :loading="cancelDialog" v-if="isCancel&treatInfo.Cancel">取消订单</el-button>
        <el-button @click="changeEmpPerformance" :loading="modifyPerformanceCommissionLoading" size="small" type="primary" v-if="isModifyPerformanceCommission&treatInfo.BillStatus == 20">修改经手人业绩提成</el-button>
      </span>
    </el-dialog>

    <!--消耗退单 订单详情-->
    <el-dialog width="1100px" :visible.sync="refundDialogVisible">
      <!-- <span slot="title">消耗退单详情</span> -->
      <el-tabs v-model="refundActiveName">
        <el-tab-pane name="0" label="消耗退单详情">
          <div style="height: 60vh" class="font_13">
            <el-scrollbar class="el-scrollbar_height">
              <TreatRefundDetailContent :treatInfo="treatInfo"></TreatRefundDetailContent>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户签字" name="1">
           <div style="height:60vh;" >
            <el-scrollbar class="el-scrollbar_height">
              <div v-if="Signature.CreatedOn" class="marbm_10">签字时间：{{Signature.CreatedOn}}</div>
              <el-card class="box-card" v-if="Signature.ImageURL">
                  <el-image style="width: 320px; height: 450px;"  :src="Signature.ImageURL" fit="cover"></el-image>
              </el-card>
            </el-scrollbar>
           </div>
        </el-tab-pane>
        <el-tab-pane label="附件" name="2">
          <div style="height:60vh;">
            <el-scrollbar class="el-scrollbar_height">
              <el-upload action="#" list-type="picture-card" :before-upload="updataimg" :file-list="fileList" :http-request="unset" :multiple="true" accept=".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <el-image class="el-upload-list__item-thumbnail" :id="file.name" :src="file.url" :preview-src-list="showFileList" :z-index="99999999999" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="refundDialogPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span v-if="isDelete" class="el-upload-list__item-delete" @click="deleteImg(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer text_center" :hidden="refundActiveName=='1'">
        <el-button @click="refundDialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="refundTreatCashierReceiptDialogVisible = true" size="small">打印小票</el-button>
        <el-button @click="changeEmpPerformance" :loading="modifyPerformanceCommissionLoading" size="small" type="primary" v-if="isModifyPerformanceCommission&treatInfo.BillStatus == 20">修改经手人业绩提成</el-button>
      </span>
    </el-dialog>

    <!-- 修改业绩 -->
    <el-dialog width="1380px" :visible.sync="modificationPerformanceVisible">
      <span slot="title">修改经手人业绩提成</span>
      <div style="height: 60vh" class="font_13">
        <el-scrollbar class="el-scrollbar_height">

          <!-- 修改详情项目 !-->
          <div v-if="Project.length > 0">
            <div v-for="(item, index) in Project" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">项目</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row class="pad_10 border_bottom border_left border_right">
                <el-col :span="6">
                  <div>
                    {{ item.ProjectName }}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </div>
                  <div class="color_red martp_5 font_12">¥ {{ item.Price | NumFormat }}</div>
                </el-col>
                <el-col :span="6">x {{ item.Quantity }}</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="item.CardPreferentialAmount < 0">+ ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="item.CardPreferentialAmount > 0">- ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div>¥ {{ (item.TreatAmount + item.LargessTreatAmount) | NumFormat }}</div>
                  <div class="martp_5 font_12">
                    <span class="color_green" v-if="item.TreatAmount>0">非赠送金额：¥ {{item.TreatAmount | NumFormat}}</span>
                    <span class="color_red" v-if="item.LargessTreatAmount>0" :class="item.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{item.LargessTreatAmount | NumFormat}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in item.TreatBillHandler" :key="index">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(1, item)">{{ handler.TreatHandlerName }}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item, employee)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <!-- 非赠送 -->
                      <el-row v-if="item.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="非赠送业绩">
                              <span slot="label">
                                非赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                  <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送比例提成">
                              <span slot="label">
                                非赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                  <p v-if="employee.Rate != null">
                                    非赠送比例参考值：{{
                                    employee.Rate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送固定提成">
                              <span slot="label">
                                非赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                  <p v-if="employee.Fixed">
                                    非赠送固定参考值：¥
                                    {{ employee.Fixed | NumFormat }}
                                  </p>
                                  <p v-else>非赠送固定参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <!-- 赠送 -->
                      <el-row v-if="item.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送业绩">
                              <span slot="label">
                                赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                  <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送比例提成">
                              <span slot="label">
                                赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                  <p v-if="employee.LargessRate != null">
                                    赠送比例参考值：{{
                                    employee.LargessRate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送固定提成">
                              <span slot="label">
                                赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                  <p v-if="employee.LargessFixed">
                                    赠送固定参考值：¥
                                    {{ employee.LargessFixed | NumFormat }}
                                  </p>
                                  <p v-else>赠送固定提成参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit != null">
                                特殊补贴参考值：¥
                                {{ employee.SpecialBenefit | NumFormat }}
                              </p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩储值卡 !-->
          <div v-if="SavingCard.length > 0">
            <div v-for="(item, index) in SavingCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">储值卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.SavingCardName }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                </div>
              </el-row>
              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="pad_10 border_bottom border_left border_right">
                  <el-col :span="6">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                    </div>
                    <div class="color_red martp_5 font_12">
                      ￥{{ middItem.Price | NumFormat }}
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="6">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="6" v-show="treatInfo.BillType == '10'">
                    <div>
                      <span v-if="(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)<0">+ ¥ {{Math.abs(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)| NumFormat}}</span>
                      <span v-else-if="(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)>0">- ¥ {{Math.abs(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)| NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </div>
                    <div class="martp_5 font_12">
                      <span class="color_gray font_12" v-if="middItem.PricePreferentialAmount != 0">
                        手动改价：
                        <span class="color_red" v-if="middItem.PricePreferentialAmount > 0">
                          - ¥
                          {{
                          middItem.PricePreferentialAmount | NumFormat
                          }}
                        </span>
                        <span class="color_green" v-else>
                          + ¥
                          {{
                          Math.abs(middItem.PricePreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                      <span class="color_gray font_12" :class="
                            middItem.PricePreferentialAmount != 0
                              ? 'marlt_15'
                              : ''
                          " v-if="middItem.CardPreferentialAmount > 0">
                        卡优惠：
                        <span class="color_red">
                          - ¥
                          {{
                          parseFloat(middItem.CardPreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>¥ {{ (middItem.TotalAmount) | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>

                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(3, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                    <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定参考值：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送固定参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定参考值：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!--  -->
                      </el-col>
                      <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改详情时效卡 !-->
          <div v-if="TimeCard.length > 0">
            <div v-for="(item, index) in TimeCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">时效卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.TimeCardName
                  }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </div>
              </el-row>

              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="border_right border_left border_bottom pad_10">
                  <el-col :span="6">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                    </div>
                    <div class="color_red martp_5 font_12">￥{{ middItem.Price | NumFormat }}</div>
                  </el-col>
                  <el-col :span="6">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="6" v-show="treatInfo.BillType == '10'">
                    <div>
                      <span v-if="middItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                      <span v-else-if="middItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </div>
                    <div class="martp_5 font_12">
                      <span class="color_gray font_12" v-if="middItem.CardPreferentialAmount != 0">
                        卡优惠
                        <span class="color_red" v-if="middItem.CardPreferentialAmount > 0">
                          - ¥
                          {{
                          middItem.CardPreferentialAmount | NumFormat
                          }}
                        </span>
                        <span class="color_green" v-else>
                          + ¥
                          {{
                          Math.abs(middItem.CardPreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(4, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送业绩金额 x 业绩占比</p>
                                    <p>注：非赠送业绩比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例提成参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定提成：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩通用次卡 !-->
          <div v-if="GeneralCard.length > 0">
            <div v-for="(item, index) in GeneralCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">通用次卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.GeneralCardName
                  }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                </div>
              </el-row>
              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="border_bottom border_left border_right pad_10">
                  <el-col :span="6">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                    </div>
                    <div class="color_red martp_5 font_12">￥{{ middItem.Price }}</div>
                  </el-col>
                  <el-col :span="6">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="6" v-show="treatInfo.BillType == '10'">
                    <div>
                      <span v-if="middItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                      <span v-else-if="middItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </div>
                    <div class="martp_5 font_12">
                      <span class="color_gray font_12" v-if="middItem.CardPreferentialAmount != 0">
                        卡优惠
                        <span class="color_red" v-if="middItem.CardPreferentialAmount > 0">
                          - ¥
                          {{
                          middItem.CardPreferentialAmount | NumFormat
                          }}
                        </span>
                        <span class="color_green" v-else>
                          + ¥
                          {{
                          Math.abs(middItem.CardPreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(5, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                    <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例提成参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定提成：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!--  -->
                      </el-col>
                      <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改详情产品 !-->
          <div v-if="Product.length > 0">
            <div v-for="(item, index) in Product" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">产品</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row class="border_bottom pad_10 border_right border_left">
                <el-col :span="6">
                  <div>{{ item.ProductName }}({{ item.Alias }})</div>
                  <div class="color_red martp_5 font_12">
                    ￥{{ item.Price }}
                    <el-tag v-if="item.IsLargess" size="mini" class="marlt_10">赠</el-tag>
                  </div>
                </el-col>
                <el-col :span="6">x {{ item.Quantity }}</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="item.CardPreferentialAmount < 0">+ ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="item.CardPreferentialAmount > 0">- ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div>¥ {{ (item.TreatAmount + item.LargessTreatAmount) | NumFormat }}</div>
                  <div class="martp_5 font_12">
                    <span class="color_green" v-if="item.TreatAmount>0">非赠送金额：¥ {{item.TreatAmount | NumFormat}}</span>
                    <span class="color_red" v-if="item.LargessTreatAmount>0" :class="item.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{item.LargessTreatAmount | NumFormat}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in item.TreatBillHandler" :key="index">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(2, item)">{{ handler.TreatHandlerName }}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item, employee)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="非赠送业绩">
                              <span slot="label">
                                非赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                  <p>注：非赠送业绩比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送比例提成">
                              <span slot="label">
                                非赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                  <p v-if="employee.Rate != null">
                                    非赠送比例参考值：{{
                                    employee.Rate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送固定提成">
                              <span slot="label">
                                非赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                  <p v-if="employee.Fixed != null">
                                    非赠送固定参考值：¥
                                    {{ employee.Fixed | NumFormat }}
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>

                      <!-- 赠送 -->
                      <el-row v-if="item.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送业绩">
                              <span slot="label">
                                赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                  <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送比例提成">
                              <span slot="label">
                                赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                  <p v-if="employee.LargessRate != null">
                                    赠送比例参考值：{{
                                    employee.LargessRate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送固定提成">
                              <span slot="label">
                                赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                  <p v-if="employee.LargessFixed">
                                    赠送固定参考值：¥
                                    {{ employee.LargessFixed | NumFormat }}
                                  </p>
                                  <p v-else>赠送固定提成参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit != null">
                                特殊补贴参考值：¥
                                {{ employee.SpecialBenefit | NumFormat }}
                              </p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩套餐卡 !-->
          <div v-if="PackageCard.length > 0">
            <div v-for="(item, index) in PackageCard" :key="index + 'a1'">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="6">套餐卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
                <el-col :span="6">消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.PackageCardName
                  }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </div>
              </el-row>
              <!--项目-->
              <div v-if="item.Project.length > 0">
                <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                  <el-row class="pad_10 border_bottom border_left border_right">
                    <el-col :span="6">
                      <div>
                        {{ middItem.ProjectName }}
                        <span v-if="middItem.Alias"></span>
                        ({{ middItem.Alias }})
                        <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        <el-tag class="marlt_5" size="mini">项目</el-tag>
                      </div>
                      <div class="color_red font_12 martp_5">¥ {{ middItem.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="6">x {{ middItem.Quantity }}</el-col>
                    <el-col :span="6" v-show="treatInfo.BillType == '10'">
                      <div>
                        <span v-if="middItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else-if="middItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else>¥ 0.00</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                      <div class="martp_5 font_12">
                        <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                        <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                    <el-col :span="2" class="padtp_10 padbm_10">
                      <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, middItem, '项目')">{{ handler.TreatHandlerName }}</el-link>
                    </el-col>
                    <el-col :span="22" class="border_left">
                      <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                        <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                            <el-form-item :label="`${employee.EmployeeName}`">
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                  changeSaleHandlerRate(middItem, employee)
                                ">
                                <template slot="append">%</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="16" class="border_left border_right">
                          <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="非赠送业绩">
                                  <span slot="label">
                                    非赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                      <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送比例提成">
                                  <span slot="label">
                                    非赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                      <p v-if="employee.Rate != null">
                                        非赠送比例参考值：{{
                                        employee.Rate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>非赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送固定提成">
                                  <span slot="label">
                                    非赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额） x 非赠送固定 x 数量</p>
                                      <p v-if="employee.Fixed != null">
                                        非赠送固定参考值：¥
                                        {{ employee.Fixed | NumFormat }}
                                      </p>
                                      <p v-else>非赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!-- 赠送 -->
                          <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="赠送业绩">
                                  <span slot="label">
                                    赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                      <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送比例提成">
                                  <span slot="label">
                                    赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                      <p v-if="employee.LargessRate != null">
                                        赠送比例参考值：{{
                                        employee.LargessRate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送固定提成">
                                  <span slot="label">
                                    赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                      <p v-if="employee.LargessFixed">
                                        赠送固定参考值：¥
                                        {{ employee.LargessFixed | NumFormat }}
                                      </p>
                                      <p v-else>赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!--  -->
                        </el-col>
                        <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                            <el-form-item label="特殊补贴">
                              <span slot="label">
                                特殊补贴
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>特殊补贴 = 特殊补贴 x 数量</p>
                                  <p v-if="employee.SpecialBenefit != null">
                                    特殊补贴参考值：¥
                                    {{ employee.SpecialBenefit | NumFormat }}
                                  </p>
                                  <p v-else>特殊补贴参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
              </div>

              <!--储值卡-->
              <div v-if="item.SavingCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.SavingCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <div>
                      {{ middItem.SavingCardName
                      }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag class="marlt_5" size="mini">储值卡</el-tag>
                    </div>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_right border_left border_bottom pad_10">
                      <el-col :span="6">
                        <div>
                          {{ chlidItem.ProjectName }}
                          <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                        </div>
                        <div class="color_red font_12 martp_5">
                          ¥ {{ chlidItem.Price | NumFormat }}
                          <el-tag v-if="chlidItem.IsLargess" size="mini" class="marlt_10">赠</el-tag>
                        </div>
                      </el-col>
                      <el-col :span="6">x {{ chlidItem.Quantity }}</el-col>

                      <el-col :span="6" v-show="treatInfo.BillType == '10'">
                        <div>
                          <span v-if="(chlidItem.PricePreferentialAmount + chlidItem.CardPreferentialAmount)<0">+ ¥ {{Math.abs(chlidItem.PricePreferentialAmount + chlidItem.CardPreferentialAmount)| NumFormat}}</span>
                          <span v-else-if="(chlidItem.PricePreferentialAmount + chlidItem.CardPreferentialAmount)>0">- ¥ {{Math.abs(chlidItem.PricePreferentialAmount + chlidItem.CardPreferentialAmount)| NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </div>
                        <div class="martp_5 font_12">
                          <span class="color_gray font_12" v-if="chlidItem.PricePreferentialAmount != 0">
                            手动改价：
                            <span class="color_red" v-if="chlidItem.PricePreferentialAmount > 0">
                              - ¥
                              {{
                              chlidItem.PricePreferentialAmount | NumFormat
                              }}
                            </span>
                            <span class="color_green" v-else>
                              + ¥
                              {{
                              Math.abs(chlidItem.PricePreferentialAmount)
                              | NumFormat
                              }}
                            </span>
                          </span>
                          <span class="color_gray font_12" :class="
                                chlidItem.PricePreferentialAmount != 0
                                  ? 'marlt_15'
                                  : ''
                              " v-if="chlidItem.CardPreferentialAmount > 0">
                            卡优惠：
                            <span class="color_red">
                              - ¥
                              {{
                              parseFloat(chlidItem.CardPreferentialAmount)
                              | NumFormat
                              }}
                            </span>
                          </span>
                        </div>
                      </el-col>

                      <el-col :span="6">
                        <div>¥ {{ (chlidItem.TotalAmount) | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '储值卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="employee.Fixed != null">
                                          非赠送固定参考值：¥
                                          {{ employee.Fixed | NumFormat }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--时效卡-->
              <div v-if="item.TimeCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.TimeCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <div>
                      {{ middItem.TimeCardName
                      }}
                      <span v-if=" middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                      <el-tag class="marlt_5" size="mini">时效卡</el-tag>
                    </div>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_left border_bottom border_right pad_10">
                      <el-col :span="6">
                        <div>
                          {{ chlidItem.ProjectName }}
                          <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                          <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                        </div>
                        <div class="color_red font_12 martp_5">¥ {{ chlidItem.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="6">x {{ chlidItem.Quantity }}</el-col>
                      <el-col :span="6" v-show="treatInfo.BillType == '10'">
                        <div>
                          <span v-if="chlidItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(chlidItem.CardPreferentialAmount) | NumFormat}}</span>
                          <span v-else-if="chlidItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(chlidItem.CardPreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </div>
                        <div class="martp_5 font_12">
                          <span class="color_gray font_12" v-if="chlidItem.CardPreferentialAmount != 0">
                            卡优惠
                            <span class="color_red" v-if="chlidItem.CardPreferentialAmount > 0">
                              - ¥
                              {{
                              chlidItem.CardPreferentialAmount | NumFormat
                              }}
                            </span>
                            <span class="color_green" v-else>
                              + ¥
                              {{
                              Math.abs(chlidItem.CardPreferentialAmount)
                              | NumFormat
                              }}
                            </span>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div>¥ {{ chlidItem.TotalAmount | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '时效卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送业绩金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="employee.Fixed != null">
                                          非赠送固定参考值：¥
                                          {{ employee.Fixed | NumFormat }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>

                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定提成参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--通用次卡-->
              <div v-if="item.GeneralCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.GeneralCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <div>
                      {{ middItem.GeneralCardName
                      }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      <el-tag class="marlt_5" size="mini">通用次卡</el-tag>
                    </div>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_left border_bottom border_left pad_10">
                      <el-col :span="6">
                        <div>
                          {{ chlidItem.ProjectName }}
                          <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                          <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        </div>
                        <div class="color_red font_12 martp_5">¥ {{ chlidItem.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="6">x {{ chlidItem.Quantity }}</el-col>
                      <el-col :span="6" v-show="treatInfo.BillType == '10'">
                        <div>
                          <span v-if="chlidItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(chlidItem.CardPreferentialAmount) | NumFormat}}</span>
                          <span v-else-if="chlidItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(chlidItem.CardPreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </div>
                        <div class="martp_5 font_12">
                          <span class="color_gray font_12" v-if="chlidItem.CardPreferentialAmount != 0">
                            卡优惠
                            <span class="color_red" v-if="chlidItem.CardPreferentialAmount > 0">
                              - ¥
                              {{
                              chlidItem.CardPreferentialAmount | NumFormat
                              }}
                            </span>
                            <span class="color_green" v-else>
                              + ¥
                              {{
                              Math.abs(chlidItem.CardPreferentialAmount)
                              | NumFormat
                              }}
                            </span>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div>¥ {{ chlidItem.TotalAmount | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '通用次卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="
                                            employee.FixedCommission != null
                                          ">
                                          非赠送固定参考值：¥
                                          {{
                                          employee.FixedCommission | NumFormat
                                          }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--产品-->
              <div v-if="item.Product.length > 0">
                <el-row v-for="(middItem, middIndex) in item.Product" :key="middIndex">
                  <el-row class="border_right border_left border_bottom pad_10">
                    <el-col :span="6">
                      <div>
                        {{ middItem.ProductName }}
                        <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                        <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <el-tag class="marlt_5" size="mini">产品</el-tag>
                      </div>
                      <div class="color_red font_12 martp_5">¥ {{ middItem.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="6">x {{ middItem.Quantity }}</el-col>
                    <el-col :span="6" v-show="treatInfo.BillType == '10'">
                      <div>
                        <span v-if="middItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else-if="middItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(middItem.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else>¥ 0.00</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                      <div class="martp_5 font_12">
                        <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                        <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                    <el-col :span="2" class="padtp_10 padbm_10">
                      <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, middItem, '产品')">{{ handler.TreatHandlerName }}</el-link>
                    </el-col>
                    <el-col :span="22" class="border_left">
                      <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                        <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                            <el-form-item :label="`${employee.EmployeeName}`">
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                  changeSaleHandlerRate(chlidItem, employee)
                                ">
                                <template slot="append">%</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="16" class="border_left border_right">
                          <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="非赠送业绩">
                                  <span slot="label">
                                    非赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                      <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送比例提成">
                                  <span slot="label">
                                    非赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                      <p v-if="employee.Rate != null">
                                        非赠送比例参考值：{{
                                        employee.Rate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>非赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送固定提成">
                                  <span slot="label">
                                    非赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                      <p v-if="employee.Fixed != null">
                                        非赠送固定参考值：¥
                                        {{ employee.Fixed | NumFormat }}
                                      </p>
                                      <p v-else>非赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!-- 赠送 -->
                          <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="赠送业绩">
                                  <span slot="label">
                                    赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                      <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送比例提成">
                                  <span slot="label">
                                    赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                      <p v-if="employee.LargessRate != null">
                                        赠送比例参考值：{{
                                        employee.LargessRate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送固定提成">
                                  <span slot="label">
                                    赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                      <p v-if="employee.LargessFixed">
                                        赠送固定参考值：¥
                                        {{ employee.LargessFixed | NumFormat }}
                                      </p>
                                      <p v-else>赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!--  -->
                        </el-col>
                        <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                            <el-form-item label="特殊补贴">
                              <span slot="label">
                                特殊补贴
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>特殊补贴 = 特殊补贴 x 数量</p>
                                  <p v-if="employee.SpecialBenefit != null">
                                    特殊补贴参考值：¥
                                    {{ employee.SpecialBenefit | NumFormat }}
                                  </p>
                                  <p v-else>特殊补贴参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
            </div>
          </div>

        </el-scrollbar>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modificationPerformanceVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSaleBill" :loading="saveTreatDialog" size="small">保存</el-button>
      </span>
    </el-dialog>

    <!-- 消耗退单修改业绩 修改业绩 -->
    <el-dialog width="1380px" :visible.sync="refundModificationPerformanceVisible">
      <span slot="title">退消耗修改经手人业绩提成</span>
      <div style="height: 60vh" class="font_13">
        <el-scrollbar class="el-scrollbar_height">
          <!-- 修改详情项目 !-->
          <div v-if="Project.length > 0">
            <div v-for="(item, index) in Project" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="8">项目</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">消耗金额</el-col>
              </el-row>
              <el-row class="pad_10 border_bottom border_left border_right">
                <el-col :span="8">
                  <div>
                    {{ item.ProjectName }}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </div>
                  <!-- <div class="color_red martp_5 font_12">
                    ¥ {{ item.Price | NumFormat }}
                  </div>-->
                </el-col>
                <el-col :span="8">x {{ item.Quantity }}</el-col>
                <el-col :span="8">
                  <div>¥ {{ (item.TreatAmount + item.LargessTreatAmount) | NumFormat }}</div>
                  <div class="martp_5 font_12">
                    <span class="color_green" v-if="item.TreatAmount>0">非赠送金额：¥ {{item.TreatAmount | NumFormat}}</span>
                    <span class="color_red" v-if="item.LargessTreatAmount>0" :class="item.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{item.LargessTreatAmount | NumFormat}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in item.TreatBillHandler" :key="index">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(1, item)">{{ handler.TreatHandlerName }}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item, employee)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <!-- 非赠送 -->
                      <el-row v-if="item.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="非赠送业绩">
                              <span slot="label">
                                非赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                  <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送比例提成">
                              <span slot="label">
                                非赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                  <p v-if="employee.Rate != null">
                                    非赠送比例参考值：{{
                                    employee.Rate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送固定提成">
                              <span slot="label">
                                非赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                  <p v-if="employee.Fixed">
                                    非赠送固定参考值：¥
                                    {{ employee.Fixed | NumFormat }}
                                  </p>
                                  <p v-else>非赠送固定参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <!-- 赠送 -->
                      <el-row v-if="item.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送业绩">
                              <span slot="label">
                                赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                  <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送比例提成">
                              <span slot="label">
                                赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                  <p v-if="employee.LargessRate != null">
                                    赠送比例参考值：{{
                                    employee.LargessRate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送固定提成">
                              <span slot="label">
                                赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                  <p v-if="employee.LargessFixed">
                                    赠送固定参考值：¥
                                    {{ employee.LargessFixed | NumFormat }}
                                  </p>
                                  <p v-else>赠送固定提成参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit != null">
                                特殊补贴参考值：¥
                                {{ employee.SpecialBenefit | NumFormat }}
                              </p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩储值卡 !-->
          <div v-if="SavingCard.length > 0">
            <div v-for="(item, index) in SavingCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="8">储值卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.SavingCardName }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                </div>
              </el-row>
              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="pad_10 border_bottom border_left border_right">
                  <el-col :span="8">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_10">赠</el-tag>
                    </div>
                    <!-- <div class="color_red martp_5 font_12">
                      ￥{{ middItem.Price | NumFormat }}
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_10">赠
                      </el-tag>
                    </div>-->
                  </el-col>
                  <el-col :span="8">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="8" v-show="treatInfo.BillType == '10'">
                    <div>
                      <span v-if="(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)<0">+ ¥ {{Math.abs(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)| NumFormat}}</span>
                      <span v-else-if="(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)>0">- ¥ {{Math.abs(middItem.PricePreferentialAmount + middItem.CardPreferentialAmount)| NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </div>
                    <div class="martp_5 font_12">
                      <span class="color_gray font_12" v-if="middItem.PricePreferentialAmount != 0">
                        手动改价：
                        <span class="color_red" v-if="middItem.PricePreferentialAmount > 0">
                          - ¥
                          {{
                          middItem.PricePreferentialAmount | NumFormat
                          }}
                        </span>
                        <span class="color_green" v-else>
                          + ¥
                          {{
                          Math.abs(middItem.PricePreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                      <span class="color_gray font_12" :class="
                            middItem.PricePreferentialAmount != 0
                              ? 'marlt_15'
                              : ''
                          " v-if="middItem.CardPreferentialAmount > 0">
                        卡优惠：
                        <span class="color_red">
                          - ¥
                          {{
                          parseFloat(middItem.CardPreferentialAmount)
                          | NumFormat
                          }}
                        </span>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>¥ {{ (middItem.TotalAmount) | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>

                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(3, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                    <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定参考值：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送固定参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定参考值：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!--  -->
                      </el-col>
                      <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改详情时效卡 !-->
          <div v-if="TimeCard.length > 0">
            <div v-for="(item, index) in TimeCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="8">时效卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.TimeCardName
                  }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </div>
              </el-row>

              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="border_right border_left border_bottom pad_10">
                  <el-col :span="8">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                    </div>
                    <!-- <div class="color_red martp_5 font_12">
                      ￥{{ middItem.Price | NumFormat }}
                    </div>-->
                  </el-col>
                  <el-col :span="8">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="8">
                    <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(4, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送业绩金额 x 业绩占比</p>
                                    <p>注：非赠送业绩比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例提成参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定提成：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩通用次卡 !-->
          <div v-if="GeneralCard.length > 0">
            <div v-for="(item, index) in GeneralCard" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="8">通用次卡</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">退消耗金额</el-col>
              </el-row>
              <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                <div>
                  {{ item.GeneralCardName
                  }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                </div>
              </el-row>
              <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                <el-row class="border_bottom border_left border_right pad_10">
                  <el-col :span="8">
                    <div>
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" class="marlt_10" type="danger">赠</el-tag>
                    </div>
                    <!-- <div class="color_red martp_5 font_12">
                      ￥{{ middItem.Price }}
                    </div>-->
                  </el-col>
                  <el-col :span="8">x {{ middItem.Quantity }}</el-col>
                  <el-col :span="8">
                    <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                    <div class="martp_5 font_12">
                      <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                      <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                  <el-col :span="2" class="padtp_10 padbm_10">
                    <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(5, middItem)">{{ handler.TreatHandlerName }}</el-link>
                  </el-col>
                  <el-col :span="22" class="border_left">
                    <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                      <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                          <el-form-item :label="`${employee.EmployeeName}`">
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(middItem, employee)">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="16" class="border_left border_right">
                        <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="非赠送业绩">
                                <span slot="label">
                                  非赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                    <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送比例提成">
                                <span slot="label">
                                  非赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                    <p v-if="employee.Rate != null">
                                      非赠送比例提成参考值：{{
                                      employee.Rate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="非赠送固定提成">
                                <span slot="label">
                                  非赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                    <p v-if="employee.Fixed != null">
                                      非赠送固定提成：¥
                                      {{ employee.Fixed | NumFormat }}
                                    </p>
                                    <p v-else>非赠送比例提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!-- 赠送 -->
                        <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                              <el-form-item label="赠送业绩">
                                <span slot="label">
                                  赠送业绩
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                    <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送比例提成">
                                <span slot="label">
                                  赠送比例提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                    <p v-if="employee.LargessRate != null">
                                      赠送比例参考值：{{
                                      employee.LargessRate | NumFormat
                                      }}%
                                    </p>
                                    <p v-else>赠送比例参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="8">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                              <el-form-item label="赠送固定提成">
                                <span slot="label">
                                  赠送固定提成
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                    <p v-if="employee.LargessFixed">
                                      赠送固定：¥
                                      {{ employee.LargessFixed | NumFormat }}
                                    </p>
                                    <p v-else>赠送固定提成参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                        <!--  -->
                      </el-col>
                      <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                        <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                          <el-form-item label="特殊补贴">
                            <span slot="label">
                              特殊补贴
                              <el-popover placement="top-start" width="200" trigger="hover">
                                <p>特殊补贴 = 特殊补贴 x 数量</p>
                                <p v-if="employee.SpecialBenefit != null">
                                  特殊补贴参考值：¥
                                  {{ employee.SpecialBenefit | NumFormat }}
                                </p>
                                <p v-else>特殊补贴参考值：无</p>
                                <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                              </el-popover>
                            </span>
                            <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                              <template slot="append">元</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>

          <!-- 修改详情产品 !-->
          <div v-if="Product.length > 0">
            <div v-for="(item, index) in Product" :key="index">
              <el-row class="border_bottom tipback_col pad_10">
                <el-col :span="8">产品</el-col>
                <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">消耗金额</el-col>
              </el-row>
              <el-row class="border_bottom pad_10 border_right border_left">
                <el-col :span="8">
                  <div>
                    {{ item.ProductName }}
                    <span v-if="item.Alia">({{ item.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" class="marlt_10">赠</el-tag>
                  </div>
                  <!-- <div class="color_red martp_5 font_12">
                    ￥{{ item.Price }}
                  </div>-->
                </el-col>
                <el-col :span="8">x {{ item.Quantity }}</el-col>
                <el-col :span="8">
                  <div>¥ {{ (item.TreatAmount + item.LargessTreatAmount) | NumFormat }}</div>
                  <div class="martp_5 font_12">
                    <span class="color_green" v-if="item.TreatAmount>0">非赠送金额：¥ {{item.TreatAmount | NumFormat}}</span>
                    <span class="color_red" v-if="item.LargessTreatAmount>0" :class="item.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{item.LargessTreatAmount | NumFormat}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in item.TreatBillHandler" :key="index">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(2, item)">{{ handler.TreatHandlerName }}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item, employee)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="非赠送业绩">
                              <span slot="label">
                                非赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                  <p>注：非赠送业绩比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送比例提成">
                              <span slot="label">
                                非赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                  <p v-if="employee.Rate != null">
                                    非赠送比例参考值：{{
                                    employee.Rate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="非赠送固定提成">
                              <span slot="label">
                                非赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                  <p v-if="employee.Fixed != null">
                                    非赠送固定参考值：¥
                                    {{ employee.Fixed | NumFormat }}
                                  </p>
                                  <p v-else>非赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>

                      <!-- 赠送 -->
                      <el-row v-if="item.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送业绩">
                              <span slot="label">
                                赠送业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                  <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送比例提成">
                              <span slot="label">
                                赠送比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                  <p v-if="employee.LargessRate != null">
                                    赠送比例参考值：{{
                                    employee.LargessRate | NumFormat
                                    }}%
                                  </p>
                                  <p v-else>赠送比例参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送固定提成">
                              <span slot="label">
                                赠送固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                  <p v-if="employee.LargessFixed">
                                    赠送固定参考值：¥
                                    {{ employee.LargessFixed | NumFormat }}
                                  </p>
                                  <p v-else>赠送固定提成参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                      <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit != null">
                                特殊补贴参考值：¥
                                {{ employee.SpecialBenefit | NumFormat }}
                              </p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- 修改员工业绩套餐卡 !-->
          <div v-if="PackageCard.length > 0">
            <div v-for="(item, index) in PackageCard" :key="index + 'a1'">
              <el-row :span="24" class="tipback_col pad_10 border_right border_left border_bottom">
                <el-col :span="8">
                  {{ item.PackageCardName}}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </el-col>
                <!-- <el-col :span="8">退消耗数量</el-col>
                <el-col :span="8">消耗金额</el-col>-->
              </el-row>
              <!--项目-->
              <div v-if="item.Project.length > 0">
                <el-row class="border_bottom row_header_package_detail pad_10 border_left border_right">
                  <el-col :span="8">套餐卡项目</el-col>
                  <el-col :span="8">退消耗数量</el-col>
                  <el-col :span="8">消耗金额</el-col>
                </el-row>
                <el-row v-for="(middItem, middIndex) in item.Project" :key="middIndex">
                  <el-row class="pad_10 border_bottom border_left border_right">
                    <el-col :span="8">
                      {{ middItem.ProjectName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                      <!-- <el-tag class="marlt_5" size="mini">项目</el-tag> -->
                    </el-col>
                    <el-col :span="8">x {{ middItem.Quantity }}</el-col>
                    <el-col :span="8">
                      <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                      <div class="martp_5 font_12">
                        <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                        <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                    <el-col :span="2" class="padtp_10 padbm_10">
                      <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, middItem, '项目')">{{ handler.TreatHandlerName }}</el-link>
                    </el-col>
                    <el-col :span="22" class="border_left">
                      <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                        <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                            <el-form-item :label="`${employee.EmployeeName}`">
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                  changeSaleHandlerRate(middItem, employee)
                                ">
                                <template slot="append">%</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="16" class="border_left border_right">
                          <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="非赠送业绩">
                                  <span slot="label">
                                    非赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                      <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送比例提成">
                                  <span slot="label">
                                    非赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                      <p v-if="employee.Rate != null">
                                        非赠送比例参考值：{{
                                        employee.Rate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>非赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送固定提成">
                                  <span slot="label">
                                    非赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额） x 非赠送固定 x 数量</p>
                                      <p v-if="employee.Fixed != null">
                                        非赠送固定参考值：¥
                                        {{ employee.Fixed | NumFormat }}
                                      </p>
                                      <p v-else>非赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!-- 赠送 -->
                          <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="赠送业绩">
                                  <span slot="label">
                                    赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                      <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送比例提成">
                                  <span slot="label">
                                    赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                      <p v-if="employee.LargessRate != null">
                                        赠送比例参考值：{{
                                        employee.LargessRate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送固定提成">
                                  <span slot="label">
                                    赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                      <p v-if="employee.LargessFixed">
                                        赠送固定参考值：¥
                                        {{ employee.LargessFixed | NumFormat }}
                                      </p>
                                      <p v-else>赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!--  -->
                        </el-col>
                        <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                            <el-form-item label="特殊补贴">
                              <span slot="label">
                                特殊补贴
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>特殊补贴 = 特殊补贴 x 数量</p>
                                  <p v-if="employee.SpecialBenefit != null">
                                    特殊补贴参考值：¥
                                    {{ employee.SpecialBenefit | NumFormat }}
                                  </p>
                                  <p v-else>特殊补贴参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
              </div>

              <!--储值卡-->
              <div v-if="item.SavingCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.SavingCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <el-col :span="8">
                      {{ middItem.SavingCardName}}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag class="marlt_5" size="mini">储值卡</el-tag>
                    </el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">消耗金额</el-col>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_right border_left border_bottom pad_10">
                      <el-col :span="8">
                        {{ chlidItem.ProjectName }}
                        <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="8">x {{ chlidItem.Quantity }}</el-col>
                      <el-col :span="8">
                        <div>¥ {{ (chlidItem.TotalAmount) | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '储值卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="employee.Fixed != null">
                                          非赠送固定参考值：¥
                                          {{ employee.Fixed | NumFormat }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--时效卡-->
              <div v-if="item.TimeCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.TimeCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <el-col :span="8">
                      {{ middItem.TimeCardName}}
                      <span v-if=" middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                      <!-- <el-tag class="marlt_5" size="mini">时效卡</el-tag> -->
                    </el-col>
                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">消耗金额</el-col>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_left border_bottom border_right pad_10">
                      <el-col :span="8">
                        {{ chlidItem.ProjectName }}
                        <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                        <el-tag v-if="middItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                      </el-col>
                      <el-col :span="8">x {{ chlidItem.Quantity }}</el-col>
                      <el-col :span="8">
                        <div>¥ {{ chlidItem.TotalAmount | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '时效卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送业绩金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="employee.Fixed != null">
                                          非赠送固定参考值：¥
                                          {{ employee.Fixed | NumFormat }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>

                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" :disabled="TargetWeight?true:false" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定提成参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" :disabled="TargetWeight?true:false" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--通用次卡-->
              <div v-if="item.GeneralCard.length > 0">
                <el-row v-for="(middItem, middIndex) in item.GeneralCard" :key="middIndex">
                  <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_right border_left border_bottom">
                    <el-col :span="8">
                      {{ middItem.GeneralCardName}}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      <!-- <el-tag class="marlt_5" size="mini">通用次卡</el-tag> -->
                    </el-col>

                    <el-col :span="8">退消耗数量</el-col>
                    <el-col :span="8">消耗金额</el-col>
                  </el-row>
                  <el-row v-for="(chlidItem, chlidIndex) in middItem.Project" :key="chlidIndex">
                    <el-row class="border_left border_bottom border_left pad_10">
                      <el-col :span="8">
                        {{ chlidItem.ProjectName }}
                        <span v-if="chlidItem.Alias">({{ chlidItem.Alias }})</span>
                        <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="8">x {{ chlidItem.Quantity }}</el-col>
                      <el-col :span="8">
                        <div>¥ {{ chlidItem.TotalAmount | NumFormat }}</div>
                        <div class="martp_5 font_12">
                          <span class="color_green" v-if="chlidItem.TreatAmount>0">非赠送金额：¥ {{chlidItem.TreatAmount | NumFormat}}</span>
                          <span class="color_red" v-if="chlidItem.LargessTreatAmount>0" :class="chlidItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{chlidItem.LargessTreatAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in chlidItem.TreatBillHandler" :key="index">
                      <el-col :span="2" class="padtp_10 padbm_10">
                        <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, chlidItem, '通用次卡')">{{ handler.TreatHandlerName }}</el-link>
                      </el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                              <el-form-item :label="`${employee.EmployeeName}`">
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                    changeSaleHandlerRate(chlidItem, employee)
                                  ">
                                  <template slot="append">%</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="chlidItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="非赠送业绩">
                                    <span slot="label">
                                      非赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                        <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送比例提成">
                                    <span slot="label">
                                      非赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                        <p v-if="employee.Rate != null">
                                          非赠送比例参考值：{{
                                          employee.Rate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>非赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="非赠送固定提成">
                                    <span slot="label">
                                      非赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                        <p v-if="
                                            employee.FixedCommission != null
                                          ">
                                          非赠送固定参考值：¥
                                          {{
                                          employee.FixedCommission | NumFormat
                                          }}
                                        </p>
                                        <p v-else>非赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!-- 赠送 -->
                            <el-row v-if="chlidItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送业绩">
                                    <span slot="label">
                                      赠送业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                        <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送比例提成">
                                    <span slot="label">
                                      赠送比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                        <p v-if="employee.LargessRate != null">
                                          赠送比例参考值：{{
                                          employee.LargessRate | NumFormat
                                          }}%
                                        </p>
                                        <p v-else>赠送比例参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送固定提成">
                                    <span slot="label">
                                      赠送固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                        <p v-if="employee.LargessFixed">
                                          赠送固定参考值：¥
                                          {{ employee.LargessFixed | NumFormat }}
                                        </p>
                                        <p v-else>赠送固定参考值：无</p>
                                        <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <!--  -->
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                            <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit != null">
                                      特殊补贴参考值：¥
                                      {{ employee.SpecialBenefit | NumFormat }}
                                    </p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-row>
              </div>

              <!--产品-->
              <div v-if="item.Product.length > 0">
                <el-row class="border_bottom row_header_package_detail pad_10">
                  <el-col :span="8">套餐卡产品</el-col>
                  <el-col :span="8">退消耗数量</el-col>
                  <el-col :span="8">消耗金额</el-col>
                </el-row>
                <el-row v-for="(middItem, middIndex) in item.Product" :key="middIndex">
                  <el-row class="border_right border_left border_bottom pad_10">
                    <el-col :span="8">
                      {{ middItem.ProductName }}
                      <span v-if="middItem.Alias">({{ middItem.Alias }})</span>
                      <el-tag v-if="middItem.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                      <!-- <el-tag class="marlt_5" size="mini">产品</el-tag> -->
                    </el-col>
                    <el-col :span="8">x {{ middItem.Quantity }}</el-col>
                    <el-col :span="8">
                      <div>¥ {{ middItem.TotalAmount | NumFormat }}</div>
                      <div class="martp_5 font_12">
                        <span class="color_green" v-if="middItem.TreatAmount>0">非赠送金额：¥ {{middItem.TreatAmount | NumFormat}}</span>
                        <span class="color_red" v-if="middItem.LargessTreatAmount>0" :class="middItem.TreatAmount !=0?'marlt_15':''">赠送金额：¥ {{middItem.LargessTreatAmount | NumFormat}}</span>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="(handler, index) in middItem.TreatBillHandler" :key="index">
                    <el-col :span="2" class="padtp_10 padbm_10">
                      <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6, middItem, '产品')">{{ handler.TreatHandlerName }}</el-link>
                    </el-col>
                    <el-col :span="22" class="border_left">
                      <el-row v-for="(employee, index) in handler.Employee" :key="employee.EmployeeID">
                        <el-col :span="4" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                            <el-form-item :label="`${employee.EmployeeName}`">
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.Scale" v-enter-number2 @input="
                                  changeSaleHandlerRate(chlidItem, employee)
                                ">
                                <template slot="append">%</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="16" class="border_left border_right">
                          <el-row v-if="middItem.TreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="非赠送业绩">
                                  <span slot="label">
                                    非赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送业绩 = 非赠送金额 x 业绩占比</p>
                                      <p>注：非赠送比例参考值和非赠送固定参考值没有设置(或无),则没有非赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.Performance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送比例提成">
                                  <span slot="label">
                                    非赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送比例提成 = 非赠送业绩 x 非赠送比例</p>
                                      <p v-if="employee.Rate != null">
                                        非赠送比例参考值：{{
                                        employee.Rate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>非赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.RateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="非赠送固定提成">
                                  <span slot="label">
                                    非赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>非赠送固定提成 = （非赠送业绩 ÷ 消耗金额）x 非赠送固定 x 数量</p>
                                      <p v-if="employee.Fixed != null">
                                        非赠送固定参考值：¥
                                        {{ employee.Fixed | NumFormat }}
                                      </p>
                                      <p v-else>非赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.FixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!-- 赠送 -->
                          <el-row v-if="middItem.LargessTreatAmount > 0" class="padtp_10 padrt_10" :class="index != 0 ? 'border_top' : ''">
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                <el-form-item label="赠送业绩">
                                  <span slot="label">
                                    赠送业绩
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送业绩 = 赠送金额 x 业绩占比</p>
                                      <p>注：赠送比例参考值和赠送固定参考值没有设置(或无),则没有赠送业绩</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 120px" v-model="employee.LargessPerformance" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送比例提成">
                                  <span slot="label">
                                    赠送比例提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送比例提成 = 赠送业绩 x 赠送比例</p>
                                      <p v-if="employee.LargessRate != null">
                                        赠送比例参考值：{{
                                        employee.LargessRate | NumFormat
                                        }}%
                                      </p>
                                      <p v-else>赠送比例参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessRateCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="8">
                              <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                <el-form-item label="赠送固定提成">
                                  <span slot="label">
                                    赠送固定提成
                                    <el-popover placement="top-start" width="200" trigger="hover">
                                      <p>赠送固定提成 = （赠送业绩 ÷ 消耗金额）x 赠送固定 x 数量</p>
                                      <p v-if="employee.LargessFixed">
                                        赠送固定参考值：¥
                                        {{ employee.LargessFixed | NumFormat }}
                                      </p>
                                      <p v-else>赠送固定参考值：无</p>
                                      <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                    </el-popover>
                                  </span>
                                  <el-input type="number" class="input_type" style="width: 100px" v-model="employee.LargessFixedCommission" v-enter-number2>
                                    <template slot="append">元</template>
                                  </el-input>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                          <!--  -->
                        </el-col>
                        <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index != 0 ? 'border_top' : ''">
                          <el-form @submit.native.prevent class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                            <el-form-item label="特殊补贴">
                              <span slot="label">
                                特殊补贴
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>特殊补贴 = 特殊补贴 x 数量</p>
                                  <p v-if="employee.SpecialBenefit != null">
                                    特殊补贴参考值：¥
                                    {{ employee.SpecialBenefit | NumFormat }}
                                  </p>
                                  <p v-else>特殊补贴参考值：无</p>
                                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style="width: 100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="refundModificationPerformanceVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSaleBill" :loading="saveTreatDialog" size="small">保存</el-button>
      </span>
    </el-dialog>

    <!--经手人弹窗-->
    <el-dialog title="经手人" :visible.sync="beauticianVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler, index) in handlerList" :key="index">
            <el-row style="max-height: 300px; overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter(
                  (item) =>
                    !handlerName ||
                    item.EmployeeName.toLowerCase().includes(
                      handlerName.toLowerCase()
                    ) ||
                    item.EmployeeID.toLowerCase().includes(
                      handlerName.toLowerCase()
                    )
                )" :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee, item)">
                  <span class="marrt_10">{{ item.EmployeeName }} [{{ item.EmployeeID }}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" :disabled="TargetWeight?true:false" type="number" size="mini" min="0" max="100" v-enter-number>
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="beauticianVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改备注弹框 -->
    <el-dialog width="30%" title="修改消耗单备注" :visible.sync="innerVisible" append-to-body>
      <el-input type="textarea" :rows="4" v-model="Remark"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateRemarkClick" size="small">保存</el-button>
      </span>
    </el-dialog>

    <!-- 打印小票 消耗订单 -->
    <el-dialog :visible.sync="treatCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="treatInfo && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消耗信息</span>
                </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(treatInfo.Name) : treatInfo.Name}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(treatInfo.PhoneNumber):treatInfo.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.EmployeeName}}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.AddressDetail}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消耗明细</span>
                </el-divider>

                <template v-for="(item,index) in treatInfo.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions  && item.CardPreferentialAmount != 0" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount<0">¥ +{{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" v-if=" item.CardPreferentialAmount>0">¥ -{{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.Amount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessAmount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          +treatInfo.Project.length
                          +getCurrentCardLength(treatInfo.SavingCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && Project.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->

                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.TreatPromotions  && Project.PricePreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+''+ item.TimeCardID+''+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCurrentCardLength(treatInfo.TimeCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCurrentCardLength(treatInfo.GeneralCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions   && item.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>

                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1
                        +treatInfo.Project.length
                        +getCardTotalLength(treatInfo.SavingCard)
                        +getCardTotalLength(treatInfo.TimeCard)
                        +getCardTotalLength(treatInfo.GeneralCard)
                        }} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions && item.CardPreferentialAmount != 0" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                      <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.CardPreferentialAmount | NumFormat}}</span> -->

                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount<0">¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                      <span class="font_12 text_right line_height_24" v-if=" item.CardPreferentialAmount>0">¥ -{{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="item.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                      </div>
                    <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.Project" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                          +treatInfo.Product.length
                          }} {{packItem.ProjectName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && packItem.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.CardPreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="item.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="item.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.SavingCard" :key="Pindex + 'PProjectID' + packItem.SavingCardID">
                      <div v-for="(Project,projectIndex) in packItem.Project" :key="projectIndex + item.PackageCardAccountID+packItem.SavingCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCurrentCardLength(item.SavingCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions  && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount<0">¥ {{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                          <span class="font_12 text_right line_height_24" v-if=" Project.CardPreferentialAmount>0">¥ -{{Math.abs(Project.CardPreferentialAmount) | NumFormat}}</span>
                        </div>

                        <div v-if="cashierReceipt.TreatPromotions  && Project.PricePreferentialAmount != 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">手动改价</span>
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.PricePreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.PricePreferentialAmount<0">¥ {{Math.abs(Project.PricePreferentialAmount) | NumFormat}}</span>
                          <span class="font_12 text_right line_height_24" v-if=" Project.PricePreferentialAmount>0">¥ -{{Math.abs(Project.PricePreferentialAmount) | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.Amount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.LargessAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 套餐卡中 时效卡 -->
                    <div v-for="(packItem,Pindex) in item.TimeCard" :key="Pindex + 'PProjectID' + packItem.TimeCardID">
                      <div v-for="(Project,ProjectIndex) in packItem.Project" :key="ProjectIndex+item.PackageCardID+packItem.TimeCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCurrentCardLength(item.TimeCard,Pindex,ProjectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.Amount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.LargessAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡中 通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.GeneralCard" :key="Pindex + 'PProjectID' + packItem.GeneralCardID">
                      <div v-for="(Project,pIndex) in packItem.Project" :key="pIndex+item.PackageCardID+packItem.GeneralCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCardTotalLength(item.TimeCard)
                            +getCurrentCardLength(item.GeneralCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions   && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.Amount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.LargessAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.Product" :key="Pindex + 'PProductID' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{+treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                          +treatInfo.Product.length
                          +item.Project.length
                          +getCardTotalLength(item.SavingCard)
                          +getCardTotalLength(item.TimeCard)
                          +getCardTotalLength(item.GeneralCard)
                          +Pindex+1
                          }} {{packItem.ProductName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions  && packItem.CardPreferentialAmount != 0" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">卡优惠</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.CardPreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="item.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="item.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex" v-if="treatInfo.PrincipalAmount">
                  <span class="flex_box font_12 color_gray text-left line_height_24">非赠送金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.PrincipalAmount | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.LargessPrincipalAmount">
                  <span class="flex_box font_12 color_gray text-left line_height_24">赠送金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.LargessPrincipalAmount | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="cashierReceipt.TreatAmount">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.Amount | NumFormat}}</span>
                </div>
                <div v-if="treatInfo.SaleBillPay && treatInfo.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in treatInfo.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="treatInfo.SaleBillPaySavingCardDeduction && treatInfo.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in treatInfo.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="treatInfo.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="treatInfo.PricePreferentialAmount>0">-￥{{treatInfo.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(treatInfo.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.CardPreferentialAmount>0 && cashierReceipt.TreatPromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{treatInfo.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="treatCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printTreatBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 退消耗订单 -->
    <el-dialog :visible.sync="refundTreatCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="treatInfo && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">退消耗信息</span>
                </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{treatInfo.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(treatInfo.Name) : treatInfo.Name}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(treatInfo.PhoneNumber):treatInfo.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.EmployeeName}}</span>
                </div>

                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{treatInfo.AddressDetail}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">退消耗明细</span>
                </el-divider>

                <template v-for="(item,index) in treatInfo.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.Amount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessAmount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          +treatInfo.Project.length
                          +getCurrentCardLength(treatInfo.SavingCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+''+ item.TimeCardID+''+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCurrentCardLength(treatInfo.TimeCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div v-for="(Project,pIndex) in item.Project" :key="pIndex+item.SavingCardID+Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{
                          treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCurrentCardLength(treatInfo.GeneralCard,index,pIndex)
                          }} {{Project.ProjectName}}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="Project.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item,index) in treatInfo.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1
                        +treatInfo.Project.length
                        +getCardTotalLength(treatInfo.SavingCard)
                        +getCardTotalLength(treatInfo.TimeCard)
                        +getCardTotalLength(treatInfo.GeneralCard)
                        }} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.Amount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.Amount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessAmount">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.LargessAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in treatInfo.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.Project" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                          +treatInfo.Product.length
                          }} {{packItem.ProjectName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div class="dis_flex" v-if="packItem.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.Amount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="packItem.LargessAmount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.LargessAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.SavingCard" :key="Pindex + 'PProjectID' + packItem.SavingCardID">
                      <div v-for="(Project,projectIndex) in packItem.Project" :key="projectIndex + item.PackageCardAccountID+packItem.SavingCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCurrentCardLength(item.SavingCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.Amount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.LargessAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 套餐卡中 时效卡 -->
                    <div v-for="(packItem,Pindex) in item.TimeCard" :key="Pindex + 'PProjectID' + packItem.TimeCardID">
                      <div v-for="(Project,ProjectIndex) in packItem.Project" :key="ProjectIndex+item.PackageCardID+packItem.TimeCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCurrentCardLength(item.TimeCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                       <div class="dis_flex" v-if="Project.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                       </div>
                       <div class="dis_flex" v-if="Project.LargessAmount">
                         <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                         <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                       </div>
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡中 通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.GeneralCard" :key="Pindex + 'PProjectID' + packItem.GeneralCardID">
                      <div v-for="(Project,pIndex) in packItem.Project" :key="pIndex+item.PackageCardID+packItem.GeneralCardID+Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                            {{
                            +treatInfo.Project.length
                            +getCardTotalLength(treatInfo.SavingCard)
                            +getCardTotalLength(treatInfo.TimeCard)
                            +getCardTotalLength(treatInfo.GeneralCard)
                            +treatInfo.Product.length
                            +item.Project.length
                            +getCardTotalLength(item.SavingCard)
                            +getCardTotalLength(item.TimeCard)
                            +getCurrentCardLength(item.GeneralCard,Pindex,projectIndex)
                            }} {{Project.ProjectName}}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{Project.Price | NumFormat}}</span>
                        </div>

                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">{{Project.Quantity}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.Amount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.Amount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex" v-if="Project.LargessAmount">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.LargessAmount | NumFormat}}</span>
                        </div>
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                          <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{Project.TotalAmount | NumFormat}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.Product" :key="Pindex + 'PProductID' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{+treatInfo.Project.length
                          +getCardTotalLength(treatInfo.SavingCard)
                          +getCardTotalLength(treatInfo.TimeCard)
                          +getCardTotalLength(treatInfo.GeneralCard)
                          +treatInfo.Product.length
                          +item.Project.length
                          +getCardTotalLength(item.SavingCard)
                          +getCardTotalLength(item.TimeCard)
                          +getCardTotalLength(item.GeneralCard)
                          +Pindex+1
                          }} {{packItem.ProductName}}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.Price | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                       <div class="dis_flex" v-if="packItem.Amount">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">非赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.Amount | NumFormat}}</span>
                       </div>
                       <div class="dis_flex" v-if="packItem.LargessAmount">
                         <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                         <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.LargessAmount | NumFormat}}</span>
                       </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex" v-if="treatInfo.PrincipalAmount">
                  <span class="flex_box font_12 color_gray text-left line_height_24">非赠送金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.PrincipalAmount | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.LargessPrincipalAmount">
                  <span class="flex_box font_12 color_gray text-left line_height_24">赠送金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.LargessPrincipalAmount | NumFormat}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{treatInfo.Amount | NumFormat}}</span>
                </div>
                <div v-if="treatInfo.SaleBillPay && treatInfo.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in treatInfo.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="treatInfo.SaleBillPaySavingCardDeduction && treatInfo.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in treatInfo.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="treatInfo.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="treatInfo.PricePreferentialAmount>0">-￥{{treatInfo.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(treatInfo.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{treatInfo.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundTreatCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printRefundTreatBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/treatBill";
import permission from "@/components/js/permission.js";
import TreatBillDetailContent from "@/views/iBeauty/Components/treatBillDetailContent";
import TreatRefundDetailContent from "@/views/iBeauty/Components/treatRefundDetailContent";
import printReceipt from "@/components/js/print";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";

var Enumerable = require("linq");
var socket;
export default {
  name: "TreatBill",
  components: {
    TreatBillDetailContent,
    TreatRefundDetailContent,
  },
  data() {
    return {
      value:0,
      showFileList: [],
      isDelete: true,
      fileList: [],
      ActiveName: 0,
      refundActiveName: "0",
      treatCashierReceiptDialogVisible: false,
      refundTreatCashierReceiptDialogVisible: false,
      modalLoading: false,
      loading: false,
      dialogVisible: false,
      beauticianVisible: false,
      innerVisible: false,
      modificationPerformanceVisible: false,
      modifyPerformanceCommissionLoading: false,
      saveTreatDialog: false,
      cancelDialog: false,
      refundDialogVisible: false,
      refundModificationPerformanceVisible: false,
      treatList: [],
      searchParams: {
        PageNum: 1,
        Name: "",
        BillID: "",
        BillStatus: "",
        BillType: "",
        createTime: "",
      },
      tabHandle: "0",
      handlerName: "",
      Project: [],
      Product: [],
      PackageCard: [],
      GeneralCard: [],
      SavingCard: [],
      TimeCard: [],
      type: "",
      // currentItem: '',
      checkList: [],
      treatInfo: {},
      handlerInfo: {},
      handlerList: [], //公共经手人列表
      projectTreatHandlers: [], //项目消耗经手人
      productTreatHandlers: [], //产品消耗经手人
      savingCardTreatHandlers: [], //储值卡消耗经手人
      detailId: "",
      IsPackageCard: "",
      /** 修改是使用的属性字段  */
      currentItem: {}, //当前修改业绩的品项
      currentItemId: "", //计算佣金时使用
      currentItemType: 0, //计算佣金时使用 商品类型，1：项目 2：产品 3：时效卡 4：储值卡 5：通用次卡6：套餐卡
      Remark: "",
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 8, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      selectHandleItem: {},
      isCancel: false, //是否允许取消订单
      isModifyPerformanceCommission: false, //是否允许修改业绩提成
      cashierReceipt: "",
      userInfo: "",
      Signature:{
        CreatedOn:'',
        ImageURL:''
      },
      TargetWeight:''
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isCancel = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-TreatBill-Cancel"
      );
      vm.isModifyPerformanceCommission = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-TreatBill-ModifyPerformanceCommission"
      );
      // 附件删除权限
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-TreatBill-DeleteAttachment"
      );
    });
  },
  methods: {
    // 查看大图
    handlePictureCardPreview(file) {
      document.getElementById(file.name).click();
    },
    refundDialogPreview(file) {
      document.getElementById(file.name).click();
    },
    unset() {},
    // 上传附件
    updataimg(file) {
      let that = this;
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpeg"
      ) {
        this.$message.error("仅支持上传jpg,png,jpeg格式!!");
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        var base64 = evt.target.result;
        var parme = {
          TreatBillID: that.detailId,
          imgAttachment: {
            AttachmentURL: base64,
            AttachmentType: "10",
          },
        };
        API.addTreatBillAttachment(parme).then((res) => {
          if (res.StateCode == 200) {
            that.fileList.push({
              name: res.Data.ID,
              url: res.Data.AttachmentURL,
              uid: res.Data.ID,
            });
            that.showFileList.push(res.Data.AttachmentURL);
          }
        });
      };
      return false;
    },
    // 获取附件
    getImageList(uid) {
      var parme = { TreatBillID: this.detailId };
      var list = [];
      API.getTreatBillAttachment(parme).then((res) => {
        if (res.StateCode == 200) {
          var list = [];
          list = res.Data.map((item) => {
            return {
              url: item.AttachmentURL,
              name: item.ID,
              status: "success",
            };
          });
          this.fileList = list;
          this.showFileList = res.Data.map((item) => {
            return item.AttachmentURL;
          });
        }
      });
    },
    // 删除附件
    deleteImg(file, fileList) {
      this.$confirm("此操作将删除当前附件, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var parme = {
            ID: file.name,
            AttachmentType: "10",
          };
          API.deleteTreatBillAttachment(parme).then((res) => {
            if (res.StateCode == 200) {
              let deleIndex = this.fileList.findIndex((item) => {
                return file.name == item.name;
              });
              this.fileList.splice(deleIndex, 1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .then(() => {});
    },
    // 获取签名
    getSignature(){
      API.getSignature({ID:this.detailId}).then(res=>{
        if(res.StateCode==200){
          if(res.Data){
            this.Signature=res.Data
          }else{
           this.Signature={
               CreatedOn:'',
               ImageURL:''
               }
          }
          
        }else{
          this.$message.error({
            message:res.Message,
            duration:2000
          })
        }
      })
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      var that = this;
      that.loading = true;
      var start = "";
      var end = "";
      if (
        that.searchParams.createTime != null &&
        that.searchParams.createTime != ""
      ) {
        start = that.searchParams.createTime[0];
        end = that.searchParams.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        Name: that.searchParams.Name,
        BillID: that.searchParams.BillID,
        StartDate: start,
        EndDate: end,
        BillStatus: that.searchParams.BillStatus,
        BillType: that.searchParams.BillType,
      };

      API.treatBilllist(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    upRemarkDialog() {
      var that = this;
      that.Remark = that.treatInfo.Remark;
      that.innerVisible = true;
    },
    //点击判断经手人
    employeeHandleClick(type, item, type_name) {
      var that = this;
      var Employee = [];
      that.selectHandleItem = item;
      switch (type) {
        case 1:
          //项目
          that.handlerList = that.projectTreatHandlers;
          that.currentItemId = item.ProjectID;
          that.currentItemType = 3;
          break;
        case 2:
          //产品
          that.handlerList = that.productTreatHandlers;
          that.currentItemId = item.ProductID;
          that.currentItemType = 1;
          break;
        case 3:
          //储值卡
          that.handlerList = that.savingCardTreatHandlers;
          that.currentItemId = item.ProjectID;
          that.currentItemType = 2;
          break;
        case 4:
          //时效卡
          that.handlerList = that.projectTreatHandlers;
          that.currentItemId = item.ProjectID;
          that.currentItemType = 3;
          break;
        case 5:
          //通用次卡
          that.handlerList = that.projectTreatHandlers;
          that.currentItemId = item.ProjectID;
          that.currentItemType = 3;
          break;
        case 6:
          if (type_name == "项目") {
            that.handlerList = that.projectTreatHandlers;
            that.currentItemId = item.ProjectID;
            that.currentItemType = 3;
          }
          if (type_name == "产品") {
            that.handlerList = that.productTreatHandlers;
            that.currentItemId = item.ProductID;
            that.currentItemType = 1;
          }
          if (type_name == "储值卡") {
            that.handlerList = that.savingCardTreatHandlers;
            that.currentItemId = item.ProjectID;
            that.currentItemType = 2;
          }
          if (type_name == "时效卡") {
            that.handlerList = that.projectTreatHandlers;
            that.currentItemId = item.ProjectID;
            that.currentItemType = 3;
          }
          if (type_name == "通用次卡") {
            that.handlerList = that.projectTreatHandlers;
            that.currentItemId = item.ProjectID;
            that.currentItemType = 3;
          }
          break;
        default:
          break;
      }
      item.TreatBillHandler.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          Employee.push({
            ID: hand.TreatHandlerID + "-" + emp.EmployeeID,
            Discount: emp.Scale,
          });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          Employee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });

      that.type = type;
      that.currentItem = item;
      that.beauticianVisible = true;
    },
    //经手人选择
    handlerCheckedChange(row, item) {
      var that = this;
      var discount = 0;
      console.log(row)
      console.log(item)
      if(that.TargetWeight){
        item.Discount = 0
        return
      }
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked == true;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    //经手人确定
    submitHandleClick() {
      var that = this;
      var treatHandlers = [];
      that.handlerList.forEach((empList) => {
        empList.Employee.forEach((hand) => {
          if (hand.Checked == true) {
            treatHandlers.push({
              TreatHandlerID: hand.TreatHandlerID,
              EmployeeID: hand.EmployeeID,
              Scale: hand.Discount,
            });
          }
        });
      });

      let params = {
        GoodsID: that.currentItemId,
        Type: that.currentItemType,
        TreatAmount: that.currentItem.TreatAmount,
        LargessTreatAmount: that.currentItem.LargessTreatAmount,
        TreatHandler: treatHandlers,
        Quantity: that.selectHandleItem.Quantity,
      };
      API.employeeCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.currentItem.TreatBillHandler = res.Data;
          that.beauticianVisible = false;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //修改经手人提成比例
    changeSaleHandlerRate(item, employee) {
      // 非赠送
      if (item.TreatAmount > 0) {
        if (employee.Fixed == null && employee.Rate == null) {
          employee.Performance = 0;
          employee.RateCommission = 0;
          employee.FixedCommission = 0;
        } else {
          employee.Performance = (
            (item.TreatAmount * employee.Scale) /
            100
          ).toFixed(2);
          if (employee.Rate != null) {
            employee.RateCommission = (
              (employee.Performance * employee.Rate) /
              100
            ).toFixed(2);
          } else {
            employee.RateCommission = 0;
          }
          if (employee.Fixed != null) {
            employee.FixedCommission = (
              (employee.Performance / item.TotalAmount) *
              employee.Fixed *
              item.Quantity
            ).toFixed(2);
          } else {
            employee.FixedCommission = 0;
          }
        }
      } else {
        employee.Performance = 0;
        employee.Rate = 0;
        employee.FixedCommission = 0;
      }
      // 赠送
      if (item.LargessTreatAmount > 0) {
        if (employee.LargessFixed == null && employee.LargessRate == null) {
          employee.LargessPerformance = 0;
          employee.LargessRateCommission = 0;
          employee.LargessFixedCommission = 0;
        } else {
          employee.LargessPerformance = (
            (item.LargessTreatAmount * employee.Scale) /
            100
          ).toFixed(2);
          if (employee.LargessRate != null) {
            employee.LargessRateCommission = (
              (employee.LargessPerformance * employee.LargessRate) /
              100
            ).toFixed(2);
          } else {
            employee.LargessRateCommission = 0;
          }
          if (employee.LargessFixed != null) {
            employee.LargessFixedCommission = (
              (employee.LargessPerformance / item.TotalAmount) *
              employee.LargessFixed *
              item.Quantity
            ).toFixed(2);
          } else {
            employee.LargessFixedCommission = 0;
          }
        }
      } else {
        employee.LargessPerformance = 0;
        employee.LargessRateCommission = 0;
        employee.LargessFixedCommission = 0;
      }
    },

    //修改备注
    updateRemarkClick() {
      var that = this;
      var params = {
        TreatBillID: that.treatInfo.ID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.innerVisible = false;
          that.treatInfo.Remark = that.Remark;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    /**详情 数据    */
    showEditDialog: function (row) {
      var that = this;
      that.loading = true;
      if (typeof row != "undefined") {
        that.detailId = row.ID;
      }
      let params = {
        ID: that.detailId,
      };
      API.treatBillinfo(params)
        .then((res) => {
          console.log(res)
          if (res.StateCode == 200) {
            that.treatInfo = res.Data;
            this.getImageList();
            this.getSignature()
            if (that.treatInfo.BillType == "10") {
              that.ActiveName = "0";
              that.dialogVisible = true;
            }
            if (that.treatInfo.BillType == "20") {
              that.refundActiveName = "0";
              that.refundDialogVisible = true;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
      that.getReceiptConfig();
    },
    /**  修改员工业绩  */
    changeEmpPerformance() {
      var that = this;
      var itemDetail = that.treatInfo
      console.log(itemDetail)
      // 时效卡
      if(itemDetail.TimeCard.length > 0 ){
        for(let i in itemDetail.TimeCard){
          if(itemDetail.TimeCard[i].TargetWeight){
            that.TargetWeight = itemDetail.TimeCard[i].TargetWeight
          }
        }
      }
      // 套餐卡
      if(itemDetail.PackageCard.length > 0 ){
        for(let i in itemDetail.PackageCard){
          // 时效卡
          if(itemDetail.PackageCard[i].TimeCard.length > 0){
            for(let j in itemDetail.PackageCard[i].TimeCard){
              that.TargetWeight = itemDetail.PackageCard[i].TimeCard[j].TargetWeight
            }
          }

        }
      }
      that.getEmployeePerformanceCommission();
    },
    /**  56.3.获取订单经手人业绩和佣金  */
    getEmployeePerformanceCommission() {
      var that = this;
      let params = {
        ID: that.detailId,
      };
      that.modifyPerformanceCommissionLoading = true;
      API.employeePerformanceCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            //项目
            res.Data.Project.forEach((p) => {
              if (p.TreatBillHandler.length == 0) {
                that.projectTreatHandlers.forEach((s) => {
                  p.TreatBillHandler.push({
                    TreatHandlerID: s.ID,
                    TreatHandlerName: s.Name,
                    Employee: [],
                  });
                });
              }
            });

            //产品
            res.Data.Product.forEach((p) => {
              if (p.TreatBillHandler.length == 0) {
                that.productTreatHandlers.forEach((s) => {
                  p.TreatBillHandler.push({
                    TreatHandlerID: s.ID,
                    TreatHandlerName: s.Name,
                    Employee: [],
                  });
                });
              }
            });

            //储值卡
            res.Data.SavingCard.forEach((p) => {
              p.Project.forEach((s) => {
                if (s.TreatBillHandler.length == 0) {
                  that.savingCardTreatHandlers.forEach((v) => {
                    s.TreatBillHandler.push({
                      TreatHandlerID: v.ID,
                      TreatHandlerName: v.Name,
                      Employee: [],
                    });
                  });
                }
              });
            });

            //通用次卡
            res.Data.GeneralCard.forEach((p) => {
              p.Project.forEach((s) => {
                if (s.TreatBillHandler.length == 0) {
                  that.productTreatHandlers.forEach((v) => {
                    s.TreatBillHandler.push({
                      TreatHandlerID: v.ID,
                      TreatHandlerName: v.Name,
                      Employee: [],
                    });
                  });
                }
              });
            });

            //时效卡
            res.Data.TimeCard.forEach((p) => {
              p.Project.forEach((s) => {
                if (s.TreatBillHandler.length == 0) {
                  that.productTreatHandlers.forEach((v) => {
                    s.TreatBillHandler.push({
                      TreatHandlerID: v.ID,
                      TreatHandlerName: v.Name,
                      Employee: [],
                    });
                  });
                }
              });
            });

            res.Data.PackageCard.forEach((p) => {
              //套餐卡->项目
              if (p.Project.length > 0) {
                p.Project.forEach((s) => {
                  if (s.TreatBillHandler.length == 0) {
                    that.projectTreatHandlers.forEach((v) => {
                      s.TreatBillHandler.push({
                        TreatHandlerID: v.ID,
                        TreatHandlerName: v.Name,
                        Employee: [],
                      });
                    });
                  }
                });
              }
              //套餐卡->产品
              if (p.Product.length > 0) {
                p.Product.forEach((s) => {
                  if (s.TreatBillHandler.length == 0) {
                    that.productTreatHandlers.forEach((v) => {
                      s.TreatBillHandler.push({
                        TreatHandlerID: v.ID,
                        TreatHandlerName: v.Name,
                        Employee: [],
                      });
                    });
                  }
                });
              }

              //套餐卡->储值卡
              if (p.SavingCard.length > 0) {
                p.SavingCard.forEach((s) => {
                  s.Project.forEach((v) => {
                    if (v.TreatBillHandler.length == 0) {
                      that.savingCardTreatHandlers.forEach((f) => {
                        v.TreatBillHandler.push({
                          TreatHandlerID: f.ID,
                          TreatHandlerName: f.Name,
                          Employee: [],
                        });
                      });
                    }
                  });
                });
              }

              //套餐卡->通用次卡
              if (p.GeneralCard.length > 0) {
                p.GeneralCard.forEach((s) => {
                  s.Project.forEach((v) => {
                    if (v.TreatBillHandler.length == 0) {
                      that.projectTreatHandlers.forEach((f) => {
                        v.TreatBillHandler.push({
                          TreatHandlerID: f.ID,
                          TreatHandlerName: f.Name,
                          Employee: [],
                        });
                      });
                    }
                  });
                });
              }

              //套餐卡->时效卡
              if (p.TimeCard.length > 0) {
                p.TimeCard.forEach((s) => {
                  s.Project.forEach((v) => {
                    if (v.TreatBillHandler.length == 0) {
                      that.projectTreatHandlers.forEach((f) => {
                        v.TreatBillHandler.push({
                          TreatHandlerID: f.ID,
                          TreatHandlerName: f.Name,
                          Employee: [],
                        });
                      });
                    }
                  });
                });
              }
            });
            that.Project = res.Data.Project;
            that.Product = res.Data.Product;
            that.PackageCard = res.Data.PackageCard;
            that.GeneralCard = res.Data.GeneralCard;
            that.SavingCard = res.Data.SavingCard;
            that.TimeCard = res.Data.TimeCard;
            if (that.treatInfo.BillType == "10") {
              that.modificationPerformanceVisible = true;
            }
            if (that.treatInfo.BillType == "20") {
              that.refundModificationPerformanceVisible = true;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modifyPerformanceCommissionLoading = false;
        });
    },
    //取消订单
    cancelOrder() {
      var that = this;
      that.cancelDialog = true;
      console.log(14);
      this.$prompt("请输入取消原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValidator: (value) => {
          if (!value) {
            return "输入不能为空";
          }
        },
      })
        .then(({ value }) => {
          var params = {
            ID: that.treatInfo.ID,
            Remark: value,
          };

          API.cancel(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: res.Message,
                  duration: 2000,
                });
                that.dialogVisible = false;
                that.handleSearch();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(() => {
              that.cancelDialog = false;
            });
        })
        .catch(() => {
          that.cancelDialog = false;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
      // this.$confirm("此操作将取消当前订单, 是否继续?", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     var params = {
      //       ID: that.treatInfo.ID,
      //     };
      //     API.cancel(params)
      //       .then((res) => {
      //         if (res.StateCode == 200) {
      //           that.$message.success({
      //             message: res.Message,
      //             duration: 2000,
      //           });
      //           that.dialogVisible = false;
      //           that.handleSearch();
      //         } else {
      //           that.$message.error({
      //             message: res.Message,
      //             duration: 2000,
      //           });
      //         }
      //       })
      //       .finally(() => {
      //         that.cancelDialog = false;
      //       });
      //   })
      //   .catch(() => {
      //     that.cancelDialog = false;
      //     this.$message({
      //       type: "info",
      //       message: "已取消操作",
      //     });
      //   });
    },
    //获取项目消耗经手人列表
    getProjectSaleHandlerList() {
      var that = this;
      API.treatGoodsProjectHandler().then((res) => {
        if (res.StateCode == 200) {
          that.projectTreatHandlers = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取产品经手人列表
    getProductSaleHandlerList() {
      var that = this;
      API.treatGoodsProductHandler().then((res) => {
        if (res.StateCode == 200) {
          that.productTreatHandlers = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取储值卡经手人列表
    getSaveCardSaleHandlerList() {
      var that = this;
      API.treatGoodsSavingCardHandler().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardTreatHandlers = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //保存业绩
    saveSaleBill() {
      var that = this;
      let project = [];
      let product = [];
      let savingCard = [];
      let generalCard = [];
      let timeCard = [];
      let packageCard = [];
      //项目
      that.Project.forEach((p) => {
        let item = [];
        p.TreatBillHandler.forEach((s) => {
          let empList = [];
          s.Employee.forEach((k) => {
            empList.push({
              EmployeeID: k.EmployeeID,
              Scale: k.Scale,
              Performance: k.Performance == null ? 0 : k.Performance,
              PerformanceRate:
                k.PerformanceRate == null ? 0 : k.PerformanceRate,
              RateCommission: k.RateCommission == null ? 0 : k.RateCommission,
              FixedCommission:
                k.FixedCommission == null ? 0 : k.FixedCommission,
              LargessPerformance:
                k.LargessPerformance == null ? 0 : k.LargessPerformance,
              LargessRateCommission:
                k.LargessRateCommission == null ? 0 : k.LargessRateCommission,
              LargessFixedCommission:
                k.LargessFixedCommission == null ? 0 : k.LargessFixedCommission,
              SpecialBenefitCommission:
                k.SpecialBenefitCommission == null
                  ? 0
                  : k.SpecialBenefitCommission,
            });
          });
          item.push({
            TreatHandlerID: s.TreatHandlerID,
            Employee: empList,
          });
        });

        project.push({
          TreatBillProjectID: p.TreatBillProjectID,
          ProjectID: p.ProjectID,
          TreatBillHandler: item,
        });
      });
      //产品
      that.Product.forEach((p) => {
        let item = [];
        p.TreatBillHandler.forEach((s) => {
          let empList = [];
          s.Employee.forEach((k) => {
            empList.push({
              EmployeeID: k.EmployeeID,
              Scale: k.Scale,
              Performance: k.Performance == null ? 0 : k.Performance,
              PerformanceRate:
                k.PerformanceRate == null ? 0 : k.PerformanceRate,
              RateCommission: k.RateCommission == null ? 0 : k.RateCommission,
              FixedCommission:
                k.FixedCommission == null ? 0 : k.FixedCommission,
              LargessPerformance:
                k.LargessPerformance == null ? 0 : k.LargessPerformance,
              LargessRateCommission:
                k.LargessRateCommission == null ? 0 : k.LargessRateCommission,
              LargessFixedCommission:
                k.LargessFixedCommission == null ? 0 : k.LargessFixedCommission,
              SpecialBenefitCommission:
                k.SpecialBenefitCommission == null
                  ? 0
                  : k.SpecialBenefitCommission,
            });
          });
          item.push({
            TreatHandlerID: s.TreatHandlerID,
            Employee: empList,
          });
        });

        product.push({
          TreatBillProductID: p.TreatBillProductID,
          ProductID: p.ProductID,
          TreatBillHandler: item,
        });
      });
      //储值卡
      that.SavingCard.forEach((p) => {
        let item = [];
        //let TreatBillSavingCardID = ""; //保存储值卡明细ID
        p.Project.forEach((s) => {
          let project = [];
          s.TreatBillHandler.forEach((k) => {
            let empItem = [];
            k.Employee.forEach((f) => {
              empItem.push({
                EmployeeID: f.EmployeeID,
                Scale: f.Scale,
                Performance: f.Performance == null ? 0 : f.Performance,
                PerformanceRate:
                  f.PerformanceRate == null ? 0 : f.PerformanceRate,
                RateCommission: f.RateCommission == null ? 0 : f.RateCommission,
                FixedCommission:
                  f.FixedCommission == null ? 0 : f.FixedCommission,
                LargessPerformance:
                  f.LargessPerformance == null ? 0 : f.LargessPerformance,
                LargessRateCommission:
                  f.LargessRateCommission == null ? 0 : f.LargessRateCommission,
                LargessFixedCommission:
                  f.LargessFixedCommission == null
                    ? 0
                    : f.LargessFixedCommission,
                SpecialBenefitCommission:
                  f.SpecialBenefitCommission == null
                    ? 0
                    : f.SpecialBenefitCommission,
              });
            });
            project.push({
              TreatHandlerID: k.TreatHandlerID,
              Employee: empItem,
            });
          });

          item.push({
            TreatBillSavingCardID: s.TreatBillSavingCardID,
            ProjectID: s.ProjectID,
            TreatBillHandler: project,
          });
        });
        savingCard.push({
          SavingCardID: p.SavingCardID,
          Project: item,
        });
      });

      //通用次卡
      that.GeneralCard.forEach((p) => {
        let item = [];
        // let TreatBillGeneralCardID = ""; //保存通用次卡明细ID
        p.Project.forEach((s) => {
          let project = [];
          s.TreatBillHandler.forEach((k) => {
            let empItem = [];
            k.Employee.forEach((f) => {
              empItem.push({
                EmployeeID: f.EmployeeID,
                Scale: f.Scale,
                Performance: f.Performance == null ? 0 : f.Performance,
                PerformanceRate:
                  f.PerformanceRate == null ? 0 : f.PerformanceRate,
                RateCommission: f.RateCommission == null ? 0 : f.RateCommission,
                FixedCommission:
                  f.FixedCommission == null ? 0 : f.FixedCommission,
                LargessPerformance:
                  f.LargessPerformance == null ? 0 : f.LargessPerformance,
                LargessRateCommission:
                  f.LargessRateCommission == null ? 0 : f.LargessRateCommission,
                LargessFixedCommission:
                  f.LargessFixedCommission == null
                    ? 0
                    : f.LargessFixedCommission,
                SpecialBenefitCommission:
                  f.SpecialBenefitCommission == null
                    ? 0
                    : f.SpecialBenefitCommission,
              });
            });
            project.push({
              TreatHandlerID: k.TreatHandlerID,
              Employee: empItem,
            });
          });

          // TreatBillGeneralCardID = s.TreatBillGeneralCardID;
          item.push({
            TreatBillGeneralCardID: s.TreatBillGeneralCardID,
            ProjectID: s.ProjectID,
            TreatBillHandler: project,
          });
        });
        generalCard.push({
          // TreatBillGeneralCardID: TreatBillGeneralCardID,
          GeneralCardID: p.GeneralCardID,
          Project: item,
        });
      });
      //时效卡
      that.TimeCard.forEach((p) => {
        let item = [];
        // let TreatBillTimeCardID = ""; //保存通用次卡明细ID
        p.Project.forEach((s) => {
          let project = [];
          s.TreatBillHandler.forEach((k) => {
            let empItem = [];
            k.Employee.forEach((f) => {
              empItem.push({
                EmployeeID: f.EmployeeID,
                Scale: f.Scale,
                Performance: f.Performance == null ? 0 : f.Performance,
                PerformanceRate:
                  f.PerformanceRate == null ? 0 : f.PerformanceRate,
                RateCommission: f.RateCommission == null ? 0 : f.RateCommission,
                FixedCommission:
                  f.FixedCommission == null ? 0 : f.FixedCommission,
                LargessPerformance:
                  f.LargessPerformance == null ? 0 : f.LargessPerformance,
                LargessRateCommission:
                  f.LargessRateCommission == null ? 0 : f.LargessRateCommission,
                LargessFixedCommission:
                  f.LargessFixedCommission == null
                    ? 0
                    : f.LargessFixedCommission,
                SpecialBenefitCommission:
                  f.SpecialBenefitCommission == null
                    ? 0
                    : f.SpecialBenefitCommission,
              });
            });
            project.push({
              TreatHandlerID: k.TreatHandlerID,
              Employee: empItem,
            });
          });

          // TreatBillTimeCardID = s.TreatBillTimeCardID;
          item.push({
            TreatBillTimeCardID: s.TreatBillTimeCardID,
            ProjectID: s.ProjectID,
            TreatBillHandler: project,
          });
        });
        timeCard.push({
          // TreatBillTimeCardID: TreatBillTimeCardID,
          TimeCardID: p.TimeCardID,
          Project: item,
        });
      });
      that.PackageCard.forEach((p) => {
        let packageProduct = [];
        let packageProject = [];
        let packageTimeCard = [];
        let packageSavingCard = [];
        let packageGeneralCard = [];
        //套餐卡->产品
        if (p.Product.length > 0) {
          p.Product.forEach((s) => {
            let item = [];
            s.TreatBillHandler.forEach((f) => {
              let empList = [];
              f.Employee.forEach((k) => {
                empList.push({
                  EmployeeID: k.EmployeeID,
                  Scale: k.Scale,
                  Performance: k.Performance == null ? 0 : k.Performance,
                  PerformanceRate:
                    k.PerformanceRate == null ? 0 : k.PerformanceRate,
                  RateCommission:
                    k.RateCommission == null ? 0 : k.RateCommission,
                  FixedCommission:
                    k.FixedCommission == null ? 0 : k.FixedCommission,
                  LargessPerformance:
                    k.LargessPerformance == null ? 0 : k.LargessPerformance,
                  LargessRateCommission:
                    k.LargessRateCommission == null
                      ? 0
                      : k.LargessRateCommission,
                  LargessFixedCommission:
                    k.LargessFixedCommission == null
                      ? 0
                      : k.LargessFixedCommission,
                  SpecialBenefitCommission:
                    k.SpecialBenefitCommission == null
                      ? 0
                      : k.SpecialBenefitCommission,
                });
              });
              item.push({
                TreatHandlerID: f.TreatHandlerID,
                Employee: empList,
              });
            });

            packageProduct.push({
              TreatBillProductID: s.TreatBillProductID,
              ProductID: s.ProductID,
              TreatBillHandler: item,
            });
          });
        }

        if (p.Project.length > 0) {
          //套餐卡->项目
          p.Project.forEach((s) => {
            let item = [];
            s.TreatBillHandler.forEach((f) => {
              let empList = [];
              f.Employee.forEach((k) => {
                empList.push({
                  EmployeeID: k.EmployeeID,
                  Scale: k.Scale,
                  Performance: k.Performance == null ? 0 : k.Performance,
                  PerformanceRate:
                    k.PerformanceRate == null ? 0 : k.PerformanceRate,
                  RateCommission:
                    k.RateCommission == null ? 0 : k.RateCommission,
                  FixedCommission:
                    k.FixedCommission == null ? 0 : k.FixedCommission,
                  LargessPerformance:
                    k.LargessPerformance == null ? 0 : k.LargessPerformance,
                  LargessRateCommission:
                    k.LargessRateCommission == null
                      ? 0
                      : k.LargessRateCommission,
                  LargessFixedCommission:
                    k.LargessFixedCommission == null
                      ? 0
                      : k.LargessFixedCommission,
                  SpecialBenefitCommission:
                    k.SpecialBenefitCommission == null
                      ? 0
                      : k.SpecialBenefitCommission,
                });
              });
              item.push({
                TreatHandlerID: f.TreatHandlerID,
                Employee: empList,
              });
            });

            packageProject.push({
              TreatBillProjectID: s.TreatBillProjectID,
              ProjectID: s.ProjectID,
              TreatBillHandler: item,
            });
          });
        }

        if (p.SavingCard.length > 0) {
          //套餐卡->储值卡
          p.SavingCard.forEach((s) => {
            let item = [];
            // let TreatBillSavingCardID = ""; //保存储值卡明细ID
            s.Project.forEach((v) => {
              let project = [];
              v.TreatBillHandler.forEach((k) => {
                let empItem = [];
                k.Employee.forEach((f) => {
                  empItem.push({
                    EmployeeID: f.EmployeeID,
                    Scale: f.Scale,
                    Performance: f.Performance == null ? 0 : f.Performance,
                    PerformanceRate:
                      f.PerformanceRate == null ? 0 : f.PerformanceRate,
                    RateCommission:
                      f.RateCommission == null ? 0 : f.RateCommission,
                    FixedCommission:
                      f.FixedCommission == null ? 0 : f.FixedCommission,
                    LargessPerformance:
                      f.LargessPerformance == null ? 0 : f.LargessPerformance,
                    LargessRateCommission:
                      f.LargessRateCommission == null
                        ? 0
                        : f.LargessRateCommission,
                    LargessFixedCommission:
                      f.LargessFixedCommission == null
                        ? 0
                        : f.LargessFixedCommission,
                    SpecialBenefitCommission:
                      f.SpecialBenefitCommission == null
                        ? 0
                        : f.SpecialBenefitCommission,
                  });
                });
                project.push({
                  TreatHandlerID: k.TreatHandlerID,
                  Employee: empItem,
                });
              });

              // TreatBillSavingCardID = v.TreatBillSavingCardID;
              item.push({
                TreatBillSavingCardID: v.TreatBillSavingCardID,
                ProjectID: v.ProjectID,
                TreatBillHandler: project,
              });
            });
            packageSavingCard.push({
              // TreatBillSavingCardID: TreatBillSavingCardID,
              SavingCardID: s.SavingCardID,
              Project: item,
            });
          });
        }
        if (p.GeneralCard.length > 0) {
          //套餐卡->通用次卡
          p.GeneralCard.forEach((s) => {
            let item = [];
            // let TreatBillGeneralCardID = ""; //保存通用次卡明细ID
            s.Project.forEach((v) => {
              let project = [];
              v.TreatBillHandler.forEach((k) => {
                let empItem = [];
                k.Employee.forEach((f) => {
                  empItem.push({
                    EmployeeID: f.EmployeeID,
                    Scale: f.Scale,
                    Performance: f.Performance == null ? 0 : f.Performance,
                    PerformanceRate:
                      f.PerformanceRate == null ? 0 : f.PerformanceRate,
                    RateCommission:
                      f.RateCommission == null ? 0 : f.RateCommission,
                    FixedCommission:
                      f.FixedCommission == null ? 0 : f.FixedCommission,
                    LargessPerformance:
                      f.LargessPerformance == null ? 0 : f.LargessPerformance,
                    LargessRateCommission:
                      f.LargessRateCommission == null
                        ? 0
                        : f.LargessRateCommission,
                    LargessFixedCommission:
                      f.LargessFixedCommission == null
                        ? 0
                        : f.LargessFixedCommission,
                    SpecialBenefitCommission:
                      f.SpecialBenefitCommission == null
                        ? 0
                        : f.SpecialBenefitCommission,
                  });
                });
                project.push({
                  TreatHandlerID: k.TreatHandlerID,
                  Employee: empItem,
                });
              });

              // TreatBillGeneralCardID = v.TreatBillGeneralCardID;
              item.push({
                TreatBillGeneralCardID: v.TreatBillGeneralCardID,
                ProjectID: v.ProjectID,
                TreatBillHandler: project,
              });
            });
            packageGeneralCard.push({
              // TreatBillGeneralCardID: TreatBillGeneralCardID,
              GeneralCardID: s.GeneralCardID,
              Project: item,
            });
          });
        }
        //套餐卡->时效卡
        if (p.TimeCard.length > 0) {
          p.TimeCard.forEach((s) => {
            let item = [];
            // let TreatBillTimeCardID = ""; //保存时效卡明细ID
            s.Project.forEach((v) => {
              let project = [];
              v.TreatBillHandler.forEach((k) => {
                let empItem = [];
                k.Employee.forEach((f) => {
                  empItem.push({
                    EmployeeID: f.EmployeeID,
                    Scale: f.Scale,
                    Performance: f.Performance == null ? 0 : f.Performance,
                    PerformanceRate:
                      f.PerformanceRate == null ? 0 : f.PerformanceRate,
                    RateCommission:
                      f.RateCommission == null ? 0 : f.RateCommission,
                    FixedCommission:
                      f.FixedCommission == null ? 0 : f.FixedCommission,
                    LargessPerformance:
                      f.LargessPerformance == null ? 0 : f.LargessPerformance,
                    LargessRateCommission:
                      f.LargessRateCommission == null
                        ? 0
                        : f.LargessRateCommission,
                    LargessFixedCommission:
                      f.LargessFixedCommission == null
                        ? 0
                        : f.LargessFixedCommission,
                    SpecialBenefitCommission:
                      f.SpecialBenefitCommission == null
                        ? 0
                        : f.SpecialBenefitCommission,
                  });
                });
                project.push({
                  TreatHandlerID: k.TreatHandlerID,
                  Employee: empItem,
                });
              });

              // TreatBillTimeCardID = v.TreatBillTimeCardID;
              item.push({
                TreatBillTimeCardID: v.TreatBillTimeCardID,
                ProjectID: v.ProjectID,
                TreatBillHandler: project,
              });
            });
            packageTimeCard.push({
              // TreatBillTimeCardID: TreatBillTimeCardID,
              TimeCardID: s.TimeCardID,
              Project: item,
            });
          });
        }

        packageCard.push({
          PackageCardID: p.PackageCardID,
          Product: packageProduct,
          Project: packageProject,
          SavingCard: packageSavingCard,
          GeneralCard: packageGeneralCard,
          TimeCard: packageTimeCard,
        });
      });
      let params = {
        ID: that.treatInfo.ID,
        Project: project,
        Product: product,
        SavingCard: savingCard,
        GeneralCard: generalCard,
        TimeCard: timeCard,
        PackageCard: packageCard,
      };
      that.saveTreatDialog = true;
      API.updateEmployeePerformanceCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: res.Message,
              duration: 2000,
            });
            that.modificationPerformanceVisible = false;
            that.dialogVisible = false;
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.saveTreatDialog = false;
        });
    },

    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    /**  获取 消耗打印内容  */
    printTreatBillContent() {
      let that = this;
      let params = {
        ID: that.treatInfo.ID,
      };
      cashierAPI
        .printTreatBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (
              let index = 0;
              index < that.cashierReceipt.PrintQuantity;
              index++
            ) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    /**  获取 退消耗 打印内容  */
    printRefundTreatBillContent() {
      let that = this;
      let params = {
        ID: that.treatInfo.ID,
      };
      cashierAPI
        .printRefundTreatBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (
              let index = 0;
              index < that.cashierReceipt.PrintQuantity;
              index++
            ) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    mathAbsData: function (item) {
      return Math.abs(item);
    },
    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },
    // 获取 当前卡项的序号
    getCurrentCardLength(savingCards, index, chileIndex) {
      let number = chileIndex + 1;
      for (let i = 0; i < savingCards.length; i++) {
        const element = savingCards[i];
        if (i < index) {
          number += element.Project.length;
        }
      }
      return number;
    },

    /**  卡项中项目总长度  */
    getCardTotalLength(Cards) {
      let number = 0;
      for (let i = 0; i < Cards.length; i++) {
        const element = Cards[i];
        number += element.Project.length;
      }
      return number;
    },
  },
  mounted() {
    var that = this;
    that.isCancel = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-TreatBill-Cancel"
    );
    that.isModifyPerformanceCommission = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-TreatBill-ModifyPerformanceCommission"
    );
    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-TreatBill-DeleteAttachment"
    );
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.treatCashierReceiptDialogVisible = false;
        that.refundTreatCashierReceiptDialogVisible = false;
      }
    });
    that.userInfo = JSON.parse(localStorage.getItem("access-user"));
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchParams.createTime = [new Date(y, m, 1), new Date()];
    that.handleSearch();
    that.getProjectSaleHandlerList();
    that.getProductSaleHandlerList();
    that.getSaveCardSaleHandlerList();
  },
};
</script>
<style lang="scss">
.TreatBill {
  
  .col_line_height {
    line-height: 35px;
  }

  .treatInfoClass {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
  }

  .el-scrollbar_height {
    height: 100%;
    .header_icon {
      background-color: #f4fbff;
      border: 1px solid #4861fc;
      padding: 15px;
      display: flex;
      align-items: center;
      border-radius: 5px;
    }
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .saleInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .employee_num {
    width: 90px;
    .el-input-group__append {
      padding: 0 10px;
    }
  }

  .back_col_f2 {
    background-color: #f8f8f8;
  }

  .el-dialog__body {
    word-break: normal;
  }

  .tipback_col {
    background-color: #ecf8ff;
  }

  .performanceInput {
    width: 60%;
    .el-input__inner {
      padding: 0 10px;
    }
    .el-input-group__append {
      padding: 0 5px;
      outline: 0;
      width: 30%;
    }
  }

  .performanceInput_1 {
    width: 35%;
    margin-left: 5px;
    .el-input__inner {
      padding: 0 10px;
    }
    .el-input-group__append {
      padding: 0 5px;
      outline: 0;
      width: 30%;
    }
  }

  .sale-ModifyPerformanceCommission-Handler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 26px;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 26px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }

  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }

  .el-input__inner {
    padding-right: 0;
  }

  .row_header_package_detail {
    background-color: #f5f7fa;
    padding: 8px;
    color: #303133;
  }

  .saleHandler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 18px;
      color: #c0c4cc;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  // /deep/.item {
  //   border-bottom: 3px solid #f2f2f2;
  // }

  .item:last-child {
    border-bottom: none;
  }
}
</style>