export default {
  /**
   * 
   * @returns 
   */
  getSocket: function(callback){
    var socket = new WebSocket('ws://127.0.0.1:13528');
    
    socket.onopen = function (event) {
      // that.getPrintList()//打印机列表
      // 监听消息
      var timer = null;
      socket.onmessage = function (event) {
        var data = JSON.parse(event.data);
        if(data.cmd == 'print'){
          if (typeof callback === 'function') {
            if (!timer) {
              callback(data)
            }
            timer = setTimeout(() => {
              timer = null
            }, 200);
            
          }
        }
        // console.log('Client received a message', event);
      };
      // 监听Socket的关闭
      socket.onclose = function (event) {
        // console.log('Client notified socket has closed', event);
      };
    };
    return socket;
  },
  /**
   * 获取请求的UUID，指定长度和进制,如 
   * getUUID(8, 2)   //"01001010" 8 character (base=2)
	 * getUUID(8, 10) // "47473046" 8 character ID (base=10)
	 * getUUID(8, 16) // "098F4D35" 8 character ID (base=16)
   * 
   * @param {长度} len 
   * @param {进制  2 8 10 16} radix 
   * @returns 随机 UUID
   */
  getPrintUUID: function (len, radix) {
		var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
		var uuid = [], i;
		radix = radix || chars.length;
		if (len) {
			for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
		} else {
			var r;
			uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
			uuid[14] = '4';
			for (i = 0; i < 36; i++) {
				if (!uuid[i]) {
					r = 0 | Math.random() * 16;
					uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
				}
			}
		}
		return uuid.join('');
  },
  /***
	 * 构造request对象
	 */
	 getRequestObject : function(cmd) {
		var request = new Object();
		request.requestID = this.getPrintUUID(8, 16);
		request.version = "1.0";
		request.cmd = cmd;
		return request;
	},

  /**
   * 发起打印命令
   * @param {打印信息} documents 
   * @param {回调函数（参数为 request）} callback 
   */
  doActionPrint: function (documents,callback) {
    if(!documents){ throw new Error('打印信息是必须的');}
    var request = new Object();
    request.cmd = "print";
    request.requestID = this.getPrintUUID(9, 10);
    request.version = "1.0"; //协议版本
    request.task = new Object();
    request.task.notifyType = ["print"];
    request.task.taskID = this.getPrintUUID(9, 10);
    request.task.documents = documents
    if(typeof callback === 'function'){
      callback(request)
    }
    
  },

  /**
   *  姓名添加 **
   * @param {需要转换的名称} name 
   * @returns 转换之后的字符串
   */
   hiddenName : function(name){
    if (!name) return "";
    var reg = new RegExp("(?<=.)./g");
    return name.replace(reg, "*")
  },
  /**
   * 手机号隐藏中间四位
   * @param { 手机号} phone 
   * @returns  转换好的字符串
   */
  hiddenPhone : function (phone) {
    if (!phone) return "";
    var reg = new RegExp("^(\\d{3})\\d{4}(\\d{4})$");
    var tel = phone.replace(reg, "$1****$2");
    return tel;
  }

}