/**
 * Created by preference on 2021/04/08
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**   */
  printSaleBillContent: params => {
    return API.POST('api/saleBill/printSaleBillContent', params)
  },
  /**  获取小票打印配置 */
  getReceiptConfig: params => {
    return API.POST('api/receiptConfig/all', params)
  },
  /**  获取小票打印配置 */
  getReceiptConfigBill: params => {
    return API.POST('api/receiptConfig/bill', params)
  },
  /** 保存小票打印配置  */
  receiptConfigCreate: params => {
    return API.POST('api/receiptConfig/create', params)
  },
  /** 获取销售退款打印内容  */
  printRefundSaleBillContent: params => {
    return API.POST('api/saleBill/printRefundSaleBillContent', params)
  },
  /** 获取 补欠款 打印内容  */
  printArrearSaleBillContent: params => {
    return API.POST('api/saleBill/printArrearSaleBillContent', params)
  },

  /** 获取 消耗 打印内容  */
  printTreatBillContent: params => {
    return API.POST('api/treatBill/printTreatBillContent', params)
  },
  
  /** 获取 退消耗 打印内容  */
  printRefundTreatBillContent: params => {
    return API.POST('api/treatBill/printRefundTreatBillContent', params)
  },


  


   
}